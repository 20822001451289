import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FooterV2 from 'shared/components/Footer/FooterV2';
import Header from './shared/components/Header/Header';
import DynamicComponent from './components/dynamicComponent';
import BlueNav from './components/blueNav';
import { fetchAllPages } from './squidexClient';
import './tailwind.css';

const DynamicPage = ({ pageData, isLoading }) => {
  const [allPages, setAllPages] = useState([]);

  useEffect(() => {
    fetchAllPages().then(setAllPages);
  }, []);

  if (isLoading || !pageData) return null;

  const parentPage = Array.isArray(pageData.parentPage)
    ? pageData.parentPage[0]
    : pageData.parentPage;
  const isSubpage = parentPage && parentPage.id;

  const subPages = isSubpage
    ? allPages
        .filter(
          page =>
            page.flatData.parentPage &&
            page.flatData.parentPage[0] &&
            page.flatData.parentPage[0].id === parentPage.id,
        )
        .sort(
          (a, b) => (a.flatData.menuOrder || 0) - (b.flatData.menuOrder || 0),
        )
    : [];

  return (
    <div className="tw-dynamic-page">
      <Header />
      {isSubpage &&
        subPages.length > 0 && (
          <BlueNav subPages={subPages} currentSlug={pageData.slug} />
        )}
      {pageData.blocks &&
        pageData.blocks.map((block, index) => (
          <DynamicComponent
            key={block.id || `block-${block.__typename}-${index}`}
            {...block}
          />
        ))}
      <FooterV2 />
    </div>
  );
};

DynamicPage.propTypes = {
  pageData: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    parentPage: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ]),
    blocks: PropTypes.arrayOf(PropTypes.object),
  }),
  isLoading: PropTypes.bool,
};

DynamicPage.defaultProps = {
  pageData: null,
  isLoading: false,
};

export default DynamicPage;
