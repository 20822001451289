import React from 'react';
import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import withClient from 'shared/components/ApolloClient/withClient';
import { makeStyles } from '@material-ui/core/styles';
import {
  ZIP_CODE_SOFT_SEARCH_V2,
  CITY_NAME_SOFT_SEARCH,
} from 'shared/constants/gql-constants';

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, {
    keys: ['label'],
  });

const useStyles = makeStyles(theme => ({
  popper: {
    position: 'relative',
  },
  searchField: {
    width: theme.spacing(50),
  },
  inline: {
    display: 'inline',
  },
  searchBar: {
    borderRadius: theme.shape.borderRadius * 6,
    width: '100%',
    overflow: 'visible',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  suggestionList: {
    width: '100%',
  },
}));

function LocationSelectField({ client, onLocationSelected }) {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [input, setInput] = React.useState('');

  React.useEffect(
    () => {
      let active = true;

      if (!input) {
        return undefined;
      }

      (async () => {
        setLoading(true);

        try {
          // if input string is not a number, search city
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(input)) {
            const { data } = await client.query({
              fetchPolicy: 'network-only',
              query: CITY_NAME_SOFT_SEARCH,
              variables: {
                cityName: input,
                limit: 5,
              },
            });
            const suggestions = data.softSearchCityName.map(suggestion => ({
              value: suggestion.id,
              label: `${suggestion.city}, ${suggestion.state}`,
            }));

            if (active) {
              setLoading(false);
              setOptions(suggestions);
            }
          } else {
            // if input string is a number, search zip code
            const { data } = await client.query({
              fetchPolicy: 'network-only',
              query: ZIP_CODE_SOFT_SEARCH_V2,
              variables: {
                zipInput: input,
                limit: 5,
              },
            });
            const suggestions = data.softSearchZipCodeV2.map(suggestion => ({
              value: suggestion.id,
              label: `${suggestion.id} ${suggestion.city}, ${suggestion.state}`,
            }));

            if (active) {
              setLoading(false);
              setOptions(suggestions);
            }
          }
        } catch (err) {
          console.error(err);

          if (active) {
            setLoading(false);
          }
        }
      })();

      return () => {
        active = false;
      };
    },
    [input],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Autocomplete
        id="asynchronous-demo"
        popupIcon={null}
        filterOptions={filterOptions}
        className={classes.searchField}
        classes={{ popper: classes.popper }}
        open={false}
        getOptionSelected={(option, value) => option.label === value.label}
        getOptionLabel={option => option.label}
        onInputChange={(_event, value, reason) => {
          if (reason === 'input') {
            setInput(value);
          }
        }}
        includeInputInList
        options={options}
        loading={loading}
        renderOption={option => (
          <ListItem disableGutters dense>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {option.label}
                  </Typography>
                </React.Fragment>
              }
              secondary={option.isHighSchoolCourse ? option.atSchools : ''}
            />
          </ListItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus
            placeholder="Search a city or ZIP code"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      <List>
        {options.map(option => (
          <ListItem
            key={option.value}
            disableGutters
            button
            onClick={() => onLocationSelected(option)}
          >
            <ListItemIcon>
              <LocationOnRoundedIcon />
            </ListItemIcon>
            <ListItemText disableGutters primary={option.label} />
          </ListItem>
        ))}
      </List>
      {loading && (
        <Grid container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      {!loading &&
        options.length === 0 &&
        input.length === 0 && (
          <Grid container justify="center" alignItems="center">
            <Typography variant="body2">
              Please type a city or zip code
            </Typography>
          </Grid>
        )}
      {!loading &&
        options.length === 0 &&
        input.length > 0 && (
          <Grid container justify="center" alignItems="center">
            <Typography variant="body2">No matching location found</Typography>
          </Grid>
        )}
    </Box>
  );
}

LocationSelectField.propTypes = {
  client: PropTypes.shape.isRequired,
  onLocationSelected: PropTypes.func.isRequired,
};

export default withClient(LocationSelectField);
