import React from 'react';
import PropTypes from 'prop-types';
import TopWave from 'shared/assets/svg/wave-top-inverse.svg';
import BottomWave from 'shared/assets/svg/rounded-wave-bottom.svg';
import BottomWaveMobile from 'shared/assets/svg/landing-wave-bottom.svg';
import butterfly from 'shared/assets/svg/butterfly-blue.svg';

const TestimonialheroComponent = ({ image, quote, name, location }) => {
  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  const getImageAlt = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0)
      return 'Testimonial';
    return imageArray[0].fileName || 'Testimonial';
  };

  return (
    <div className="tw-relative tw-overflow-hidden tw-bg-[rgb(96,190,247)]">
      <div
        className="tw-absolute tw-inset-0 tw-bg-no-repeat tw-bg-cover"
        style={{
          backgroundImage: `url(${butterfly})`,
          backgroundSize: '75vw',
          backgroundPosition: '-7em 2em',
          '@media (minWidth: 768px)': {
            backgroundSize: '50vw',
            backgroundPosition: '0 0',
          },
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw-top-0 tw-w-full">
        <img
          src={TopWave}
          className="tw-w-full tw-h-[185px] sm:tw-h-[115px]"
          alt=""
        />
      </div>
      <div className="tw-text-center tw-px-[5%] tw-relative tw-z-10 tw-max-w-[1440px] tw-mx-auto">
        <img
          src={getImageUrl(image)}
          alt={getImageAlt(image)}
          className="tw-w-[212px] tw-h-[212px] tw-mx-auto tw-mt-32 tw-object-cover tw-rounded-full"
        />
        <div className="tw-text-center tw-text-white tw-text-3xl tw-font-medium lg:tw-px-8 tw-whitespace-pre-wrap">
          <p>{quote}</p>
        </div>
        <div className="tw-text-center tw-text-white tw-text-2xl tw-mt-6 tw-mb-6 tw-mb-20">
          <p className="tw-font-bold">{name}</p>
          <p>{location}</p>
        </div>
      </div>
      <div className="tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw-bottom-0 tw-min-w-full">
        <img
          src={BottomWave}
          className="tw-w-full tw-hidden sm:tw-block"
          alt=""
        />
        <img
          src={BottomWaveMobile}
          className="tw-w-[768px] tw--ml-1/2 tw-block sm:tw-hidden"
          alt=""
        />
      </div>
    </div>
  );
};

TestimonialheroComponent.propTypes = {
  image: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ).isRequired,
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default TestimonialheroComponent;
