/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import TopWave from 'shared/assets/svg/wave-top-light-blue.svg';
import TopWaveGrey from 'shared/assets/svg/rounded-wave-bottom-inverse-grey.svg';

const TextandimageComponent = ({ title, text, themecolor, image }) => {
  const isBlue = themecolor === 'blue';
  const isGray = themecolor === 'gray';

  const containerClass = isBlue
    ? 'tw-bg-[#56b0e4] tw-text-white'
    : isGray
      ? 'tw-bg-[rgb(243,243,244)] tw-text-black'
      : 'tw-bg-white tw-text-black';

  const titleClass = isBlue ? 'tw-text-white' : 'tw-text-[#56b0e4]';

  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  return (
    <div className="tw-w-full">
      {(isBlue || isGray) && (
        <div className="tw-flex tw-justify-center tw-pointer-events-none tw-w-full tw-h-[185px] sm:tw-h-[115px]">
          <img
            src={isBlue ? TopWave : TopWaveGrey}
            alt=""
            className={`tw-w-full tw-h-full ${
              isGray ? 'tw-transform tw-rotate-180' : ''
            }`}
          />
        </div>
      )}
      <div className={`tw-w-full ${containerClass}`}>
        <div className="tw-max-w-[1440px] tw-mx-auto tw-px-[5%] tw-py-12">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={8}>
              <Typography variant="h4" className={`${titleClass}`}>
                {title}
              </Typography>
              <div
                className="tw-text-lg tw-space-y-4 tw-mt-4"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {image &&
                image.length > 0 && (
                  <img
                    src={getImageUrl(image)}
                    alt={image[0].fileName || title}
                    className="tw-w-full tw-h-auto tw-rounded-lg"
                    width={image[0].width}
                    height={image[0].height}
                  />
                )}
            </Grid>
          </Grid>
        </div>
      </div>
      {isGray && (
        <div className="tw-flex tw-justify-center tw-pointer-events-none tw-w-full tw-h-[185px] sm:tw-h-[115px]">
          <img src={TopWaveGrey} alt="" className="tw-w-full tw-h-full" />
        </div>
      )}
    </div>
  );
};

TextandimageComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  themecolor: PropTypes.oneOf(['white', 'blue', 'gray']).isRequired,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ),
};

TextandimageComponent.defaultProps = {
  image: [],
};

export default TextandimageComponent;
