import React from 'react';
import PropTypes from 'prop-types';
import TopWaveWhite from 'shared/assets/svg/wave-bottom-white.svg';
import TopWaveGrey from 'shared/assets/svg/rounded-wave-bottom-inverse-grey.svg';

const SubpageheaderComponent = ({
  title,
  subtitle,
  subpageColor,
  showRoundedShape,
}) => {
  const bgColor =
    subpageColor === 'gray' ? 'tw-bg-[rgb(243,243,244)]' : 'tw-bg-white';
  const TopWave = subpageColor === 'gray' ? TopWaveGrey : TopWaveWhite;

  return (
    <div
      className={`tw-relative tw-overflow-hidden ${bgColor} tw-w-full tw-mb-[-50px] tw--z-[1]`}
    >
      <div className="tw-max-w-[1440px] tw-mx-auto tw-px-[5%] tw-relative tw-z-10">
        <div className="tw-flex tw-flex-col tw-justify-center tw-h-[180px] tw-my-12 md:tw-text-center lg:tw-text-left">
          <h1 className="tw-text-4xl xs:tw-text-5xl lg:tw-text-5xl xl:tw-text-6xl tw-font-bold tw-text-[#56b0e4] tw-mb-4">
            {title}
          </h1>
          {subtitle && (
            <h2 className="tw-text-2xl tw-text-[#555]">{subtitle}</h2>
          )}
        </div>
      </div>
      {showRoundedShape && (
        <div
          className={`tw-w-full ${
            subpageColor === 'gray' ? 'tw-bg-white' : ''
          }`}
        >
          <img
            src={TopWave}
            className="tw-w-full tw-h-[185px] sm:tw-h-[115px]"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

SubpageheaderComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subpageColor: PropTypes.oneOf(['white', 'gray']),
  showRoundedShape: PropTypes.bool,
};

SubpageheaderComponent.defaultProps = {
  subtitle: '',
  subpageColor: 'white',
  showRoundedShape: false,
};

export default SubpageheaderComponent;
