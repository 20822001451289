import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import withClient from 'shared/components/ApolloClient/withClient';
import SearchTabs from 'shared/components/tabs/SearchTabs';
import SearchTab from 'shared/components/tab/SearchTab';
import { makeStyles } from '@material-ui/core/styles';
import withFocusable from 'shared/components/HigherOrderComponent/withFocusable';
import { COURSE_NAME_SOFT_SEARCH } from 'shared/constants/gql-constants';
import { isEmpty } from 'shared/utilities/utils';
import { setCourse } from 'shared/actions/tutorSearchV2';
import CourseResultsListV2 from './CourseResultsListV2';

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline',
  },
  searchBar: {
    borderRadius: theme.shape.borderRadius * 6,
    width: '100%',
    overflow: 'visible',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  listContainer: {
    maxHeight: theme.spacing(50),
    overflowY: 'scroll',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  popupContainer: {
    position: 'relative',
    width: '100%',
  },
  popupContent: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(80),
    },
  },
  switchContainer: {
    marginBottom: theme.spacing(1),
  },
}));

function SearchBarV2({
  client,
  searchTabIndex,
  onSetTutorSearchInput,
  onSearchContextSwitch,
  courseSelected,
  blurParentView,
  ...props
}) {
  const classes = useStyles();
  const [courseInput, setCourseInput] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState(
    isEmpty(courseSelected)
      ? null
      : {
          label: courseSelected,
          isHighSchoolCourse: true,
        },
  );
  const [tutorInput, setTutorInput] = React.useState('');
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSelectOption = option => {
    setSelectedOption({
      label: option.label,
      isHighSchoolCourse: option.isHighSchoolCourse,
    });
    setCourseInput(option.label);
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(
    () => {
      if (courseSelected) {
        setSelectedOption({
          label: courseSelected,
          isHighSchoolCourse: true,
        });
        setCourseInput(courseSelected);
      }
    },
    [courseSelected],
  );

  React.useEffect(
    () => {
      let active = true;

      if (!courseInput) {
        return undefined;
      }

      (async () => {
        setLoading(true);

        try {
          const { data } = await client.query({
            fetchPolicy: 'network-only',
            query: COURSE_NAME_SOFT_SEARCH,
            variables: {
              name: courseInput,
            },
          });
          const courseSuggestions = data.softSearchCourses.map(
            courseSuggestion => {
              let trueCourseName = '';
              if (courseSuggestion.isHighSchoolCourse) {
                trueCourseName = courseSuggestion.fullCourseName;
              } else {
                trueCourseName =
                  courseSuggestion.fullCourseName +
                  courseSuggestion.atSchools[0];
              }
              return {
                value: courseSuggestion.id,
                label: courseSuggestion.fullCourseName,
                trueCourseName,
                subjects: courseSuggestion.coreSubjects,
                atSchools: courseSuggestion.atSchools,
                isHighSchoolCourse: courseSuggestion.isHighSchoolCourse,
                description: courseSuggestion.atSchools[0],
              };
            },
          );
          const coursesFiltered = matchSorter(courseSuggestions, courseInput, {
            keys: ['trueCourseName'],
          });

          if (active) {
            setCourseOptions(coursesFiltered);
            setLoading(false);
          }
        } catch (err) {
          console.error(err);

          if (active) {
            setLoading(false);
          }
        }
      })();

      return () => {
        active = false;
      };
    },
    [courseInput, searchTabIndex],
  );

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      alignItems="center"
      justify="center"
    >
      <Grid
        xs
        item
        container
        alignItems="center"
        justify="center"
        className={classes.switchContainer}
      >
        <SearchTabs
          value={searchTabIndex}
          onChange={onSearchContextSwitch}
          aria-label="Search Type"
          variant="standard"
        >
          <SearchTab label="Courses" />
          <SearchTab label="Tutors" />
        </SearchTabs>
      </Grid>

      <Grid xs item container alignItems="center" justify="center">
        <Paper elevation={2} className={classes.searchBar}>
          <Box display="flex" alignItems="center">
            <Box pl={2}>
              <SearchRoundedIcon className={classes.muiIcon} />
            </Box>
            <Box pl={2} pr={2} flexGrow={1}>
              {searchTabIndex === 0 && (
                <Autocomplete
                  id="asynchronous-demo"
                  popupIcon={null}
                  style={{ width: '100%' }}
                  open={false}
                  onClick={() => handleOpen()}
                  onOpen={() => handleOpen()}
                  getOptionSelected={(option, value) =>
                    option.label === value.label
                  }
                  getOptionLabel={option => option.label}
                  onInputChange={(_event, value, reason) => {
                    if (reason === 'input') {
                      setCourseInput(value);
                    } else if (reason === 'clear') {
                      setCourseInput('');
                    }
                  }}
                  includeInputInList
                  value={selectedOption}
                  inputValue={courseInput}
                  options={courseOptions}
                  loading={loading}
                  renderOption={option => (
                    <ListItem disableGutters dense>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="subtitle1"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {option.label}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={
                          option.isHighSchoolCourse ? option.atSchools : ''
                        }
                      />
                    </ListItem>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search by subject"
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              )}

              {searchTabIndex === 1 && (
                <TextField
                  style={{ width: '100%' }}
                  placeholder="Who would you like to connect with?"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={e => {
                    setTutorInput(e.target.value);
                    onSetTutorSearchInput(e.target.value);
                  }}
                  value={tutorInput}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Grid>
      {searchTabIndex === 0 &&
        isOpen && (
          <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.popupContainer}>
              <Box
                zIndex="modal"
                position="absolute"
                display="flex"
                className={classes.popupContent}
              >
                <Grow in={isOpen}>
                  <Paper style={{ width: '100%' }} elevation={9}>
                    <Box display="flex" flexDirection="column">
                      <CourseResultsListV2
                        setSelectedOption={handleSelectOption}
                        courseOptions={courseOptions}
                        loading={loading}
                        courseInput={courseInput}
                        setCourse={props.setCourse}
                        client={client}
                        blurParentView={blurParentView}
                      />
                    </Box>
                  </Paper>
                </Grow>
              </Box>
            </div>
          </ClickAwayListener>
        )}
    </Grid>
  );
}

SearchBarV2.propTypes = {
  client: PropTypes.object.isRequired, // eslint-disable-line
  onCourseSelected: PropTypes.func.isRequired,
  onSetTutorSearchInput: PropTypes.func.isRequired,
  searchTabIndex: PropTypes.number.isRequired,
  onSearchContextSwitch: PropTypes.func.isRequired,
  courseSelected: PropTypes.string.isRequired,
  blurParentView: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  courseSelected: state.tutorSearchV2.courseSelected,
});

export default compose(
  withFocusable,
  withClient,
  connect(mapStateToProps, { setCourse }),
)(SearchBarV2);
