import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(3),
    minHeight: '100vh',
    paddingBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  searchSummary: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  linkText: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500,
    fontFamily: 'Raleway',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  popoverContainer: {
    marginTop: theme.spacing(2),
  },
  locationContainer: {
    width: '100%',
    height: theme.spacing(8),
  },
  onlineLessonNotifierContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  onlineLessonNotifier: {
    textAlign: 'center',
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: theme.spacing(50),
    minHeight: theme.spacing(10),
  },
  cameraIcon: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
});

export default withStyles(styles);
