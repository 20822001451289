import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as StyleConstants from 'shared/constants/styleConstants';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: StyleConstants.buttonBlue,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: '#fff',
    cursor: 'pointer',
    width: '100%',
    minWidth: '80px',
    textTransform: 'none',
    '&:focus': {
      backgroundColor: StyleConstants.buttonBlueFocus,
      outline: 0,
    },
    '&:hover': {
      backgroundColor: StyleConstants.buttonBlueFocus,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  connectButtonContainerFullWidth: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  connectButtonContainer: {
    width: 'auto',
  },
  iconContainer: {
    justifyContent: 'left',
  },
  textContainer: {
    textAlign: 'center',
    flex: '1',
  },
});

const PrimaryButtonV2 = props => (
  <div
    className={
      props.fullWidth
        ? props.classes.connectButtonContainerFullWidth
        : props.classes.connectButtonContainer
    }
  >
    <Button className={props.classes.root} variant="contained" {...props}>
      {props.icon && (
        <div className={props.classes.iconContainer}>{props.icon}</div>
      )}
      <div className={props.classes.textContainer}>
        <Typography variant="button">{props.children}</Typography>
      </div>
    </Button>
  </div>
);

PrimaryButtonV2.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
};

PrimaryButtonV2.defaultProps = {
  fullWidth: false,
  icon: null,
};

export default withStyles(styles)(PrimaryButtonV2);
