import React from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const TutorCardTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 0,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '12px',
    fontFamily: 'Raleway',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default TutorCardTab;
