// FeatureiconComponent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';

const FeatureiconComponent = ({
  title,
  description,
  icon,
  link,
  linkText,
  color,
}) => {
  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  return (
    <div
      className={`tw-flex tw-flex-col tw-items-center tw-justify-start tw-h-full tw-p-4 tw-text-${color}`}
    >
      <div className="tw-bg-[#00346c] tw-rounded-full tw-flex tw-items-center tw-justify-center tw-h-[150px] tw-w-[150px] md:tw-h-[125px] md:tw-w-[125px] tw-mb-4">
        {icon &&
          icon.length > 0 && (
            <img
              src={getImageUrl(icon)}
              alt={title}
              className="tw-h-[55%] tw-w-[55%] tw-object-contain"
              width={icon[0].width}
              height={icon[0].height}
            />
          )}
      </div>
      <h2 className="tw-text-xl tw-font-bold tw-text-center tw-mb-4">
        {title}
      </h2>
      <p className="tw-text-center tw-mb-6 tw-flex-grow tw-whitespace-pre-line">
        {description}
      </p>
      {link && (
        <Button text={linkText || 'Learn More'} link={link} color="orange" />
      )}
    </div>
  );
};

FeatureiconComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ),
  link: PropTypes.string,
  linkText: PropTypes.string,
  color: PropTypes.oneOf(['black', 'white']),
};

FeatureiconComponent.defaultProps = {
  color: 'black',
  link: '',
  linkText: 'Learn More',
  icon: [],
};

export default FeatureiconComponent;
