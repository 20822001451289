import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TopWave from 'shared/assets/svg/wave-top-dark-blue.svg';
import withStyles from './ContactHeroNodeStyles';

const ContactHeroNode = ({
  title,
  subtitle,
  description,
  image,
  classes,
  children,
}) => (
  <React.Fragment>
    <div className={classes.blueContainer} style={{ marginBottom: '-4em' }}>
      <img src={TopWave} width="100%" alt="" />
    </div>
    <div className={classes.heroContent}>
      <div className={classes.innerContent}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={7}
            alignItems="start"
            justify="center"
          >
            <img className={classes.image} src={image} alt="A Tutorfly Tutor" />
          </Grid>
          <Grid item container xs={12} md={6} lg={5}>
            <Grid
              container
              justify="center"
              alignItems={{
                xs: 'start',
                lg: 'center',
              }}
              direction="column"
              wrap="nowrap"
              className={classes.textContainer}
              item
              xs={12}
            >
              <Grid item>
                <Typography variant="h2" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: '1rem' }}>
                <p className={classes.description}>{description}</p>
              </Grid>
              <Grid item>{children}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  </React.Fragment>
);

ContactHeroNode.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(ContactHeroNode);
