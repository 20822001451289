/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import matchSorter from 'match-sorter';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DuoRoundedIcon from '@material-ui/icons/DuoRounded';
import Paper from '@material-ui/core/Paper';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import SearchBarV2 from 'marketplace/components/search/SearchBarV2';
import TutorCardV2 from 'shared/components/FindATutor/components/TutorCard/TutorCardV2';
import withClient from 'shared/components/ApolloClient/withClient';
import LocationSelectField from 'shared/components/LocationSelect/LocationSelectField';
import SearchChipEmpty from 'shared/components/Chip/SearchChipEmpty';
import SearchChipSelected from 'shared/components/Chip/SearchChipSelected';
import {
  retrieveTutors,
  setLocationFilter,
  setStudentGradeFilter,
  setCourse,
  setLocationDescription,
} from 'shared/actions/tutorSearchV2';
import { SHOW_REQUEST_A_TUTOR_FORM } from 'shared/constants/index';
import { TUTOR_NAME_SOFT_SEARCH } from 'shared/constants/gql-constants';
import { isEmpty } from 'shared/utilities/utils';
import SearchNullStateV2 from './SearchNullStateV2';
import SearchTutorNameNullState from './SearchTutorNameNullState';
import withSearchStyles from './SearchStyles';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    minHeight: '100vh',
    paddingBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  gridContainer: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  searchSummary: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  linkText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 500,
    fontFamily: 'Raleway',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popoverContainer: {
    marginTop: theme.spacing(2),
  },
  locationContainer: {
    width: '100%',
    height: theme.spacing(8),
  },
  onlineLessonNotifierContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  onlineLessonNotifier: {
    textAlign: 'center',
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: theme.spacing(50),
    minHeight: theme.spacing(10),
  },
  cameraIcon: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  showMoreContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
  tutorSearchNullStateText: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  emptyState: {
    paddingTop: theme.spacing(15),
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(80),
    },
  },
  emptyStateSubtitle: {
    padding: theme.spacing(3),
    paddingTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(80),
    },
  },
}));

function SearchV2({
  client,
  title,
  localTutors,
  onlineTutors,
  exhaustedLocalTutors,
  exhaustedOnlineTutors,
  isLoading,
  nextPageLoading,
  courseSelected,
  isLocalSearch,
  locationDescription,
  fetchError,
  currentUser,
  ...props
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTabIndex, setSearchTabIndex] = React.useState(0);
  const [locationFilterOpen, setLocationFilterOpen] = React.useState(false);
  const [studentGradeFilterOpen, setStudentGradeFilterOpen] = React.useState(
    false,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [studentGrade, setStudentGrade] = React.useState('');
  const hasTutors =
    !fetchError && (localTutors.length > 0 || onlineTutors.length > 0);

  const fetchTutors = async () => {
    try {
      props.retrieveTutors(courseSelected, client, true);
    } catch (err) {
      enqueueSnackbar('There was an issue fetching tutors.', {
        variant: 'error',
      });
    }
  };

  const moreTutorsButtonIsVisible = () => {
    const shouldShow =
      !(exhaustedLocalTutors && exhaustedOnlineTutors) &&
      !(localTutors.length === 0 && onlineTutors.length === 0);
    return shouldShow;
  };

  React.useEffect(
    () => {
      if (!courseSelected) {
        return undefined;
      }

      (async () => {
        fetchTutors();
      })();

      return undefined;
    },
    [courseSelected],
  );

  const handleLocationFilterClick = event => {
    setLocationFilterOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleLocationFilterClose = () => {
    setLocationFilterOpen(false);
    setAnchorEl(null);
  };

  const handleStudentGradeFilterOpen = event => {
    setStudentGradeFilterOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleStudentGradeFilterClose = () => {
    setStudentGradeFilterOpen(false);
    setAnchorEl(null);
  };

  const onLocationSelected = locationOption => {
    props.setLocationFilter(locationOption.value, client);
    props.setLocationDescription(locationOption.label);
    handleLocationFilterClose();
  };

  const setSelectedStudentGrade = newStudentGrade => {
    if (newStudentGrade.target.value !== undefined) {
      setStudentGrade(newStudentGrade.target.value);
      props.setStudentGradeFilter(newStudentGrade.target.value, client);
      handleStudentGradeFilterClose();
    }
  };

  const [isTutorSearchLoading, setTutorSearchLoading] = React.useState(false);
  const [tutorNameSearchInput, setTutorNameSearchInput] = React.useState('');
  const [tutorNameSearchResults, setTutorNameSearchResults] = React.useState(
    [],
  );
  const [tutorNameSearchPage, setTutorNameSearchPage] = React.useState(1);
  const [exhaustedTutorNames, setExhaustedTutorNames] = React.useState(false);
  const [
    nextPageTutorSearchLoading,
    setNextPageTutorSearchLoading,
  ] = React.useState(false);

  const moreTutorsByNameButtonIsVisible = () => {
    const shouldShow =
      !exhaustedTutorNames && !(tutorNameSearchResults.length === 0);
    return shouldShow;
  };

  const fetchTutorsByName = async resetPage => {
    let active = true;
    let pageNumber;
    if (resetPage) {
      pageNumber = 1;
    } else {
      pageNumber = tutorNameSearchPage;
    }
    if (isEmpty(tutorNameSearchInput)) {
      return undefined;
    }

    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: TUTOR_NAME_SOFT_SEARCH,
        variables: {
          name: tutorNameSearchInput,
          pageNumber,
        },
      });
      const tutorSuggestions = data.getTutorsByName.map(tutorSuggestion => ({
        label: `${tutorSuggestion.profile.firstName} ${
          tutorSuggestion.profile.lastName
        }`,
        tutor: tutorSuggestion,
      }));
      const tutorsFiltered = matchSorter(
        tutorSuggestions,
        tutorNameSearchInput,
        {
          keys: ['label'],
        },
      );
      const hasExhaustedTutorNames = tutorsFiltered.length < 10;
      if (active) {
        const newSearchResults = [...tutorNameSearchResults, ...tutorsFiltered];
        if (resetPage) {
          setTutorNameSearchResults(tutorsFiltered);
        } else {
          setTutorNameSearchResults(newSearchResults);
        }
        setTutorSearchLoading(false);
        setNextPageTutorSearchLoading(false);
        setTutorNameSearchPage(pageNumber + 1);
        setExhaustedTutorNames(hasExhaustedTutorNames);
      }
    } catch (err) {
      console.error(err);

      if (active) {
        setTutorSearchLoading(false);
        setNextPageTutorSearchLoading(false);
      }
    }

    return () => {
      active = false;
    };
  };

  React.useEffect(
    () => {
      setTutorNameSearchPage(1);
      setTutorNameSearchResults([]);
      if (!tutorNameSearchInput) {
        return undefined;
      }
      setTutorSearchLoading(true);
      fetchTutorsByName(true);
      return undefined;
    },
    [tutorNameSearchInput],
  );

  const loadNextTutorNamePage = () => {
    setNextPageTutorSearchLoading(true);
    fetchTutorsByName(false);
  };

  const onNullStateCourseSelect = course => {
    props.setCourse(course, client);
  };

  const onCourseSelected = course => {
    props.setCourse(course, client);
  };

  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.container}>
        <div>
          <SearchBarV2
            onCourseSelected={onCourseSelected}
            searchTabIndex={searchTabIndex}
            onSetTutorSearchInput={setTutorNameSearchInput}
            onSearchContextSwitch={(_e, newIndex) =>
              setSearchTabIndex(newIndex)
            }
          />
        </div>

        {searchTabIndex === 0 && (
          <React.Fragment>
            {isLoading && (
              <React.Fragment>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  style={{ paddingTop: '100px' }}
                >
                  <CircularProgress />
                </Grid>
              </React.Fragment>
            )}

            {!isLoading &&
              !courseSelected && (
                <React.Fragment>
                  <SearchNullStateV2
                    onNullStateCourseSelect={onNullStateCourseSelect}
                  />
                </React.Fragment>
              )}

            {!isLoading &&
              !!courseSelected &&
              hasTutors && (
                <React.Fragment>
                  <div className={classes.searchSummary}>
                    {!isLocalSearch && (
                      <Typography variant="h4">
                        Top {courseSelected} Tutors
                      </Typography>
                    )}
                    {isLocalSearch && (
                      <Typography variant="h4">
                        Top {courseSelected} Tutors near {locationDescription}
                      </Typography>
                    )}
                    {!isLocalSearch && (
                      <Typography variant="body1" color="textPrimary">
                        Tutors can hold 1:1 lessons using our{' '}
                        {
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.thelessonspace.com/"
                            className={classes.linkText}
                          >
                            Online Lesson Space
                          </a>
                        }.
                      </Typography>
                    )}
                    {!isLocalSearch && (
                      <Typography variant="body1" color="textPrimary">
                        Interested in searching locally?{' '}
                        <span
                          className={classes.linkText}
                          onClick={handleLocationFilterClick}
                        >
                          Try searching a location
                        </span>.
                      </Typography>
                    )}
                  </div>

                  <Box display="flex" flexDirection="row">
                    {/* Filter chip to enter zip code */}
                    <Box pr={2}>
                      {!isLocalSearch && (
                        <SearchChipEmpty
                          icon={<LocationOnRoundedIcon />}
                          label={
                            <Typography variant="subtitle1">
                              Location
                            </Typography>
                          }
                          onClick={handleLocationFilterClick}
                        />
                      )}
                      {isLocalSearch && (
                        <SearchChipSelected
                          icon={<LocationOnRoundedIcon />}
                          labelText={locationDescription}
                          onClick={handleLocationFilterClick}
                        />
                      )}
                      <Popover
                        open={locationFilterOpen}
                        anchorEl={anchorEl}
                        onClose={handleLocationFilterClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        className={classes.popoverContainer}
                      >
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" pt={2}>
                            <Box flexGrow={1} pl={2} pr={1}>
                              <Typography variant="h6">Set Location</Typography>
                            </Box>
                            <Box pr={1} onClick={handleLocationFilterClose}>
                              <CloseIcon className={classes.closeIcon} />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box pl={2} pt={0.5}>
                              <Typography variant="body2">
                                Find top tutors near you!
                              </Typography>
                            </Box>
                          </Box>

                          <Box display="flex" pl={2} pr={2} pt={2} pb={2}>
                            <LocationSelectField
                              onLocationSelected={onLocationSelected}
                            />
                          </Box>
                        </Box>
                      </Popover>
                    </Box>

                    {/* Filter chip to select student grade level */}
                    <Box>
                      {!studentGrade && (
                        <SearchChipEmpty
                          icon={<FaceRoundedIcon />}
                          label={
                            <Typography variant="subtitle1">
                              {"Student's Grade Level"}
                            </Typography>
                          }
                          onClick={handleStudentGradeFilterOpen}
                        />
                      )}
                      {studentGrade && (
                        <SearchChipSelected
                          icon={<FaceRoundedIcon />}
                          labelText={studentGrade}
                          onClick={handleStudentGradeFilterOpen}
                        />
                      )}
                      <Popover
                        open={studentGradeFilterOpen}
                        anchorEl={anchorEl}
                        onClose={handleStudentGradeFilterClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        className={classes.popoverContainer}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          style={{ width: '400px' }}
                        >
                          <Box display="flex" pt={2}>
                            <Box flexGrow={1} pl={2} pr={1}>
                              <Typography variant="h6">
                                {`Select your student's grade level.`}
                              </Typography>
                            </Box>
                            <Box pr={1} onClick={handleLocationFilterClose}>
                              <CloseIcon className={classes.closeIcon} />
                            </Box>
                          </Box>
                          <Box display="flex" pl={2} pr={2} pt={2} pb={2}>
                            <FormControl>
                              <RadioGroup
                                aria-label="studentGrade"
                                name="grade"
                                value={studentGrade}
                                onClick={event =>
                                  setSelectedStudentGrade(event)
                                }
                              >
                                <FormControlLabel
                                  value="Elementary"
                                  control={<Radio />}
                                  label="Elementary"
                                />
                                <FormControlLabel
                                  value="Middle School"
                                  control={<Radio />}
                                  label="Middle School"
                                />
                                <FormControlLabel
                                  value="High School"
                                  control={<Radio />}
                                  label="High School"
                                />
                                <FormControlLabel
                                  value="College"
                                  control={<Radio />}
                                  label="College and above"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Box>
                      </Popover>
                    </Box>
                  </Box>

                  <Box flexDirection="column">
                    {localTutors.map(tutor => (
                      <TutorCardV2
                        key={tutor.id}
                        // client={client}
                        tutor={tutor}
                      />
                    ))}
                    {isLocalSearch && (
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className={classes.onlineLessonNotifierContainer}
                      >
                        <Paper
                          variant="outlined"
                          className={classes.onlineLessonNotifier}
                        >
                          <DuoRoundedIcon
                            className={classes.cameraIcon}
                            style={{ fontSize: 60 }}
                          />
                          <Typography className={classes.boldText} variant="h6">
                            Our online tutors below can help teach{' '}
                            {courseSelected} using our online lesson space!
                          </Typography>
                        </Paper>
                      </Grid>
                    )}
                    {onlineTutors.map(tutor => <TutorCardV2 tutor={tutor} />)}
                  </Box>

                  {!nextPageLoading &&
                    moreTutorsButtonIsVisible() && (
                      <Grid
                        className={classes.showMoreContainer}
                        container
                        alignContent="center"
                        justify="center"
                      >
                        <Grid item>
                          <PrimaryButtonV3 onClick={async () => fetchTutors()}>
                            Show more
                          </PrimaryButtonV3>
                        </Grid>
                      </Grid>
                    )}
                </React.Fragment>
              )}

            {!isLoading &&
              !!courseSelected &&
              !hasTutors &&
              (SHOW_REQUEST_A_TUTOR_FORM || !currentUser) && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  className={classes.gridContainer}
                >
                  <Typography variant="h5" className={classes.emptyState}>
                    Please reach out to our team to get a personal
                    recommendation to one of our top {courseSelected} tutors by
                    clicking the button below!
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.emptyStateSubtitle}
                  >
                    Our team will personally reach out to our tutors on your
                    behalf and follow up with a tutor who fits your needs within
                    24 hours.
                  </Typography>
                  <div className={classes.ctaButton}>
                    <PrimaryButtonV3 size="large" href="request-a-tutor">
                      Request a Tutor
                    </PrimaryButtonV3>
                  </div>
                </Grid>
              )}

            {nextPageLoading &&
              !isLoading && (
                <Grid
                  className={classes.showMoreContainer}
                  container
                  alignContent="center"
                  justify="center"
                >
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
          </React.Fragment>
        )}

        {searchTabIndex === 1 && (
          <React.Fragment>
            {!isTutorSearchLoading &&
              isEmpty(tutorNameSearchInput) && (
                <div>
                  <Typography
                    variant="h6"
                    className={classes.tutorSearchNullStateText}
                  >
                    Search a tutor by name with the search bar above.
                  </Typography>
                  <SearchTutorNameNullState />
                </div>
              )}

            {isTutorSearchLoading && (
              <Grid
                className={classes.showMoreContainer}
                container
                alignContent="center"
                justify="center"
              >
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}

            {!isTutorSearchLoading &&
              !isEmpty(tutorNameSearchInput) &&
              tutorNameSearchResults.length === 0 && (
                <Typography
                  variant="h6"
                  className={classes.tutorSearchNullStateText}
                >{`No tutors matched the name "${tutorNameSearchInput}."`}</Typography>
              )}

            {!isTutorSearchLoading && (
              <Box flexDirection="column">
                {tutorNameSearchResults.map(tutorSuggestion => (
                  <TutorCardV2
                    tutor={tutorSuggestion.tutor}
                    key={tutorSuggestion.tutor.id}
                  />
                ))}
              </Box>
            )}
            {!isTutorSearchLoading &&
              !nextPageTutorSearchLoading &&
              moreTutorsByNameButtonIsVisible() && (
                <Grid
                  className={classes.showMoreContainer}
                  container
                  alignContent="center"
                  justify="center"
                >
                  <Grid item>
                    <PrimaryButtonV3
                      onClick={async () => loadNextTutorNamePage()}
                    >
                      Show more
                    </PrimaryButtonV3>
                  </Grid>
                </Grid>
              )}
          </React.Fragment>
        )}
        {!isTutorSearchLoading &&
          nextPageTutorSearchLoading && (
            <Grid
              className={classes.showMoreContainer}
              container
              alignContent="center"
              justify="center"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  courseSelected: state.tutorSearchV2.courseSelected,
  localTutors: state.tutorSearchV2.localTutors,
  onlineTutors: state.tutorSearchV2.onlineTutors,
  exhaustedLocalTutors: state.tutorSearchV2.exhaustedLocalTutors,
  exhaustedOnlineTutors: state.tutorSearchV2.exhaustedOnlineTutors,
  zip: state.tutorSearchV2.zip,
  studentGrade: state.tutorSearchV2.studentGrade,
  isLocalSearch: state.tutorSearchV2.isLocalSearch,
  isLoading: state.tutorSearchV2.isLoading,
  nextPageLoading: state.tutorSearchV2.nextPageLoading,
  locationDescription: state.tutorSearchV2.locationDescription,
  fetchError: state.tutorSearchV2.fetchError,
  currentUser: state.subscriptions.currentUser,
});

SearchV2.propTypes = {
  client: PropTypes.object.isRequired, // eslint-disable-line
  title: PropTypes.string.isRequired,
  courseSelected: PropTypes.string.isRequired,
  localTutors: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onlineTutors: PropTypes.arrayOf(PropTypes.shape).isRequired,
  exhaustedLocalTutors: PropTypes.bool,
  exhaustedOnlineTutors: PropTypes.bool,
  retrieveTutors: PropTypes.func.isRequired,
  setLocationFilter: PropTypes.func.isRequired,
  setStudentGradeFilter: PropTypes.func.isRequired,
  setLocationDescription: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
  isLocalSearch: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextPageLoading: PropTypes.bool.isRequired,
  locationDescription: PropTypes.string.isRequired,
  fetchError: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape.isRequired,
};

SearchV2.defaultProps = {
  exhaustedLocalTutors: false,
  exhaustedOnlineTutors: false,
};

export default compose(
  withClient,
  withSearchStyles,
  connect(mapStateToProps, {
    retrieveTutors,
    setLocationFilter,
    setStudentGradeFilter,
    setLocationDescription,
    setCourse,
  }),
)(SearchV2);
