import React from 'react';
import PropTypes from 'prop-types';
import TopWave from 'shared/assets/svg/rounded-wave-bottom-inverse.svg';
import Button from './button';

const primaryColorDark = '#00346c';

const WaveSvg = ({ isTop }) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1920 264"
    className={`tw-w-full tw-h-full ${
      isTop ? 'tw-transform tw-rotate-180' : ''
    }`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ffffff"
      d="M0,80.2c0,0,321.6-128.1,834.6-60.6S1375.2,240.4,1920,265H0V80.2z"
    />
  </svg>
);

const BlueheroComponent = ({ title, headline, text, image, button }) => {
  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  return (
    <div
      className="tw-relative tw-overflow-hidden tw-text-white tw-pt-12"
      style={{ backgroundColor: primaryColorDark }}
    >
      <div className="tw-absolute tw-inset-x-0 tw-top-0 tw-h-48 tw-pointer-events-none tw--mb-[100px]">
        <img src={TopWave} width="100%" alt="" />
      </div>

      <div className="tw-max-w-[1440px] tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-relative tw-z-10">
        <div className="tw-grid md:tw-grid-cols-12 tw-gap-8 tw-items-center">
          <div className="md:tw-col-span-5 lg:tw-col-span-5 tw-flex tw-flex-col tw-justify-center tw-py-20 md:tw-py-40 md:tw-pr-12">
            {title && (
              <h2 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-mb-2">
                {title}
              </h2>
            )}
            {headline && (
              <h1 className="tw-text-4xl md:tw-text-5xl tw-font-bold tw-mb-6">
                {headline}
              </h1>
            )}
            <div className="tw-mb-8 tw-whitespace-pre-line">
              <p>{text}</p>
            </div>
            {button && (
              <div>
                <Button
                  text={button.text || 'Learn More'}
                  link={button.link}
                  color={button.color || 'orange'}
                />
              </div>
            )}
          </div>
          <div className="md:tw-col-span-7 lg:tw-col-span-7 tw-flex tw-justify-center md:tw-justify-end">
            {image &&
              image.length > 0 && (
                <img
                  src={getImageUrl(image)}
                  alt={title}
                  className="tw-w-auto tw-h-[480px] md:tw-h-[740px] tw-object-cover tw-mt-0 md:tw-mt-13 tw--mb-4 md:tw-mb-0"
                />
              )}
          </div>
        </div>
      </div>

      <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-h-48 tw-pointer-events-none tw-transform tw-scale-x-[-1] tw-z-20">
        <WaveSvg isTop={false} />
      </div>
    </div>
  );
};

BlueheroComponent.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string,
  text: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ),
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};

WaveSvg.propTypes = {
  isTop: PropTypes.bool.isRequired,
};

BlueheroComponent.defaultProps = {
  headline: '',
  image: [],
  button: null,
};

export default BlueheroComponent;
