import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { reusableChipStyles } from 'shared/constants/styleConstants';

/**
 * If passing custom stylings, take note of these default ones to see how to
 * access
 */
const useDefaultStyles = makeStyles({
  label: {
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  deleteIconColorPrimary: {
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  },
  fileClass: reusableChipStyles,
});

function FileChip({ onClick, onDelete, label, classes, color }) {
  const styles = classes || useDefaultStyles();

  // extract out fileClass property, save rest in new variable
  // since this includes fileClass which isn't an acceptable prop, remove it
  const { fileClass, ...chipClasses } = styles;

  return (
    <Chip
      icon={<InsertDriveFileIcon />}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      clickable
      color={color}
      className={styles.fileClass}
      classes={{ ...chipClasses }}
    />
  );
}

FileChip.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  label: PropTypes.string.isRequired,
  classes: PropTypes.shape,
  color: PropTypes.string,
};

FileChip.defaultProps = {
  classes: undefined,
  color: 'primary',
  // noop
  onDelete: undefined,
};

export default FileChip;
