import React from 'react';
import PropTypes from 'prop-types';

const getImageUrl = imageArray => {
  if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
  const firstImage = imageArray[0];
  return firstImage.url || firstImage.thumbnailUrl || '';
};

const getAltText = (logo, index, type) => {
  if (logo && logo.length > 0 && logo[0].fileName) {
    return logo[0].fileName;
  }
  return `${type} Logo ${index + 1}`;
};

const PartnersComponent = ({
  sectionTitle,
  verifiedByTitle,
  verifiedByDescription,
  verifiedByLogos,
  partnersTitle,
  partnersDescription,
  partnerLogos,
}) => (
  <div className="tw-pb-24 tw-pb-7 tw-block">
    <div className="tw-max-w-[1440px] tw-mx-auto">
      <h4 className="tw-text-center tw-text-3xl tw-font-bold">
        {sectionTitle}
      </h4>

      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-3 tw-divide-x tw-divide-black tw-mb-12">
        <div className="tw-flex tw-flex-col tw-justify-between sm:tw-mx-8 tw-text-center lg:tw-text-left">
          <div>
            <h4 className="tw-text-2xl tw-font-bold tw-mb-4">
              {verifiedByTitle}
            </h4>
            <p>{verifiedByDescription}</p>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mt-auto tw-w-full tw-justify-center lg:tw-justify-start">
            {verifiedByLogos.map((logo, index) => (
              <img
                key={logo.verifiedByLogo[0].id}
                src={getImageUrl(logo.verifiedByLogo)}
                className="tw-inline-block tw-h-[70px] tw-m-2 tw-shadow-md"
                alt={getAltText(logo.verifiedByLogo, index, 'Verified By')}
              />
            ))}
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-justify-between sm:tw-mx-8 tw-text-center lg:tw-text-left lg:tw-pl-12">
          <div>
            <h4 className="tw-text-2xl tw-font-bold tw-mb-4">
              {partnersTitle}
            </h4>
            <p>{partnersDescription}</p>
          </div>
          <div className="tw-flex tw-flex-wrap tw-mt-auto tw-w-full tw-justify-center lg:tw-justify-start">
            {partnerLogos.map((logo, index) => (
              <img
                key={logo.partnerLogo[0].id}
                src={getImageUrl(logo.partnerLogo)}
                className="tw-inline-block tw-h-[70px] tw-m-2 tw-shadow-md"
                alt={getAltText(logo.partnerLogo, index, 'Partner')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

PartnersComponent.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  verifiedByTitle: PropTypes.string.isRequired,
  verifiedByDescription: PropTypes.string.isRequired,
  verifiedByLogos: PropTypes.arrayOf(
    PropTypes.shape({
      verifiedByLogo: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          fileName: PropTypes.string,
          fileSize: PropTypes.number,
          mimeType: PropTypes.string,
          thumbnailUrl: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
  partnersTitle: PropTypes.string.isRequired,
  partnersDescription: PropTypes.string.isRequired,
  partnerLogos: PropTypes.arrayOf(
    PropTypes.shape({
      partnerLogo: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          fileName: PropTypes.string,
          fileSize: PropTypes.number,
          mimeType: PropTypes.string,
          thumbnailUrl: PropTypes.string,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default PartnersComponent;
