import {
  UPDATE_PERSONAL_INFO,
  UPDATE_ACADEMIC_INFO,
  CLEAR_PROSPECTIVE_TUTOR,
} from '../types';

export const updatePersonalInfo = (field, value) => ({
  type: UPDATE_PERSONAL_INFO,
  payload: {
    field,
    value,
  },
});

export const updateAcademicInfo = (field, item, checked) => ({
  type: UPDATE_ACADEMIC_INFO,
  payload: {
    field,
    item,
    checked,
  },
});

export const clearProspectiveTutor = () => ({
  type: CLEAR_PROSPECTIVE_TUTOR,
});
