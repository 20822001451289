import React from 'react';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const SearchTab = withStyles(({ breakpoints, typography }) => ({
  root: {
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      fontWeight: typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#fefefe',
    },
    minHeight: 44,
    minWidth: 96,
    [breakpoints.up('md')]: {
      minWidth: 120,
    },
  },
  wrapper: {
    textTransform: 'initial',
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

export default SearchTab;
