import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';
import RichText from './richtext';
import PlaceholderComponent from './placeholderComponent';
import LandingHero from './landingHero';
import '../tailwind.css';
import ColumnsComponent from './ColumnsComponent';
import FeatureiconComponent from './FeatureiconComponent';
import BlueheroComponent from './BlueheroComponent';
import CardComponent from './CardComponent';
import TestimonialheroComponent from './TestimonialheroComponent';
import PartnersComponent from './PartnersComponent';
import SubpageheaderComponent from './SubpageheaderComponent';
import TextandimageComponent from './TextandimageComponent';
import MeettheteamComponent from './MeettheteamComponent';
import MediaitemComponent from './MediaitemComponent';
import ChunkComponent from './ChunkComponent';
import BlogHero from '../marketplace/routes/schools/blog/BlogHero';

// Map of all blocks
const components = {
  ButtonComponent: Button,
  RichtextComponent: RichText,
  LandingheroComponent: LandingHero,
  ColumnsComponent,
  FeatureiconComponent,
  BlueheroComponent,
  CardComponent,
  TestimonialheroComponent,
  PartnersComponent,
  SubpageheaderComponent,
  TextandimageComponent,
  MeettheteamComponent,
  MediaitemComponent,
  ChunkComponent,
  BlogheroComponent: BlogHero,
};

const DynamicComponent = ({ __typename, ...props }) => {
  if (components[__typename]) {
    const Component = components[__typename];
    return (
      <div className="tw-dynamic-component">
        <Component {...props} />
      </div>
    );
  }
  console.error(`Component ${__typename} not found`);
  return <PlaceholderComponent typename={__typename} {...props} />;
};

DynamicComponent.propTypes = {
  __typename: PropTypes.string.isRequired,
};

export default DynamicComponent;
