import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    margin: 'auto 0',
    height: '100%',
  },
  pageTitleBackground: {
    backgroundColor: '#F3F3F4',
  },
  pageTitleContainer: {
    textAlign: 'left',
    position: 'relative',
    zIndex: '1',
    backgroundColor: '#F3F3F4',
    height: '180px',
    margin: '0 auto',
    maxWidth: '1440px',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '3em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '2em',
    },
  },
  pageTitle: {
    color: '#56b0e4',
  },
  wave: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      height: '70px',
    },
  },
  breadcrumb: {
    color: '#444444',
    fontSize: '14px',
    textDecoration: 'none',
  },
});

export default withStyles(styles);
