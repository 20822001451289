import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { setCourse } from 'shared/actions/tutorSearchV2';
import withClient from 'shared/components/ApolloClient/withClient';

const courseOptions = [
  {
    label: 'Algebra 1',
    subjects: 'Math',
  },
  {
    label: 'English',
    subjects: 'English',
    description: '',
  },
  {
    label: 'Spanish I',
    subjects: 'Spanish',
    description: '',
  },
  {
    label: 'Physics',
    subjects: 'Physics',
    description: '',
  },
  {
    label: 'SAT',
    subjects: 'SAT',
    description: '',
  },
  {
    label: 'Geometry',
    subjects: 'Math',
    description: '',
  },
];

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    width: '100%',
  },
  paper: {
    width: '100%',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function SearchNullStateV2({ client, onNullStateCourseSelect, ...props }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h6" className={classes.title}>
        Try one of our commonly searched subjects below
      </Typography>

      <Grid container spacing={3}>
        {courseOptions.map(course => (
          <Grid item xs={12} sm={6} md={4} key={course.label}>
            <ButtonBase
              disableRipple
              disableTouchRipple
              onClick={() => props.setCourse(course.label, client)}
              style={{ width: '100%' }}
            >
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h5">{course.label}</Typography>
              </Paper>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}

SearchNullStateV2.propTypes = {
  onNullStateCourseSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired,
};
export default compose(withClient, connect(null, { setCourse }))(
  SearchNullStateV2,
);
