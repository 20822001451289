import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { compose } from 'redux';
import withClient from 'shared/components/ApolloClient/withClient';
import ProfileAvatar from 'shared/components/ProfileAvatar/ProfileAvatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import Typography from '@material-ui/core/Typography';
import SessionsIcon from '@material-ui/icons/LibraryBooksRounded';
import InboxIcon from '@material-ui/icons/ChatBubbleRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import LensRoundedIcon from '@material-ui/icons/LensRounded';
import KeyboardDownArrowIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountSettings from '@material-ui/icons/SettingsApplicationsRounded';
import Referral from '@material-ui/icons/RedeemRounded';
import Request from '@material-ui/icons/SupervisedUserCircleRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToAppRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import {
  navigateEditProfile,
  navigateAccountSettings,
  navigateClientReferral,
  navigateBecomeATutor,
  navigateFindTutorByName,
  navigateFindATutor,
  navigateToSessions,
  navigateToInbox,
  navigateTutorReferral,
  navigateTutorReferralForClient,
  navigateToPathName,
  navigateAdminPanel,
} from 'shared/utilities/navigation';
import { CLEAR_NOTIFICATIONS } from 'shared/constants/gql-constants';
import {
  ProfileType,
  AVATAR_STYLE_HEADER_BAR,
  AVATAR_STYLE_XS,
  SHOW_REQUEST_A_TUTOR_FORM,
} from 'shared/constants/index';
import { isNotEmpty, capitalizeFirstLetter } from 'shared/utilities/utils';
import { switchToTutor, switchToClient } from 'shared/actions/dashboard';
import { logoutUser, clearNotifications } from 'shared/actions/subscriptions';
import withWidth from '@material-ui/core/withWidth';
import Link from 'shared/components/Link/Link';
import withStyles from './HeaderStyles';
import SetupNotifications from './SetupNotifications';
import NotificationItem from './components/NotificationItem/NotificationItem';
import tutorPrimaryLogo from './tutorfly-logo-primary.svg';
import tutorSecondaryLogo from './tutorfly-logo-orange.svg';
import hamburgerIconImage from './bars-solid.svg';
import {
  loggedOutDrawerOptions,
  loggedInDrawerOptions,
} from './headerTileData';
import { fetchTopLevelPages } from '../../../squidexClient';
import BlueNav from '../../../marketplace/components/BlueNav/BlueNav';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      squidexPages: [],
      openNotif: false,
      anchorEl: null,
      isDesktop: true,
      mobileOpen: false,
      isHoveredMenu: false,
      notificationsLoading: false,
      notificationError: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
    this.fetchSquidexPages();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  async fetchSquidexPages() {
    try {
      const topLevelPages = await fetchTopLevelPages();
      this.setState({ squidexPages: topLevelPages });
    } catch (error) {
      console.error('Error fetching Squidex pages:', error);
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleContextClick = event => {
    const { isTutorContext } = this.props;

    if (isTutorContext) {
      this.props.switchToClient();
    } else {
      this.props.switchToTutor();
    }

    event.stopPropagation();
  };

  handleMenuHover = menuType => {
    clearTimeout(this.leaveTimeout);
    this.setState({ isHoveredMenu: menuType });
  };

  handleMenuLeave = () => {
    this.leaveTimeout = setTimeout(() => {
      this.setState({ isHoveredMenu: '' });
    }, 750);
  };

  clearStateNotifications = client => async () => {
    const { clearNotifications: reduxClearNotifications } = this.props;
    this.setState({ notificationsLoading: true, notificationError: false });
    try {
      await client.mutate({ mutation: CLEAR_NOTIFICATIONS });
      reduxClearNotifications();
      this.setState({ notificationsLoading: false });
    } catch (err) {
      this.setState({ notificationsLoading: false, notificationError: true });
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ openMenu: false });
    this.setState({ openNotif: false });
  };

  toggleOpenMenu = event => {
    const isMenuOpen = this.state.openMenu;
    this.setState({ openMenu: !isMenuOpen });
    this.handleClick(event);
  };

  toggleOpenNotif = event => {
    const isNotifOpen = this.state.openNotif;
    this.setState({ openNotif: !isNotifOpen });
    this.handleClick(event);
  };

  editProfile = (platform = 'desktop') => {
    this.handleClose();
    navigateEditProfile();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  accountSettings = (platform = 'desktop') => {
    this.handleClose();
    navigateAccountSettings();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  findTutorBySubject = (platform = 'desktop') => {
    this.handleClose();
    navigateFindATutor();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  findTutorByName = (platform = 'desktop') => {
    this.handleClose();
    navigateFindTutorByName();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  referFriends = (platform = 'desktop') => {
    this.handleClose();
    navigateClientReferral();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  navigateTutorReferTutor = (platform = 'desktop') => {
    this.handleClose();
    navigateTutorReferral();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  navigateTutorReferClient = (platform = 'desktop') => {
    this.handleClose();
    navigateTutorReferralForClient();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  navBecomeATutor = (platform = 'desktop') => {
    this.handleClose();
    navigateBecomeATutor();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  navAdminPanel = (platform = 'desktop') => {
    this.handleClose();
    navigateAdminPanel();
    if (platform === 'mobile') this.toggleOpenMenu();
  };

  logout = (platform = 'desktop') => {
    this.handleClose();
    this.props.logoutUser();
    if (platform === 'mobile') {
      this.toggleOpenMenu();
    }
    navigateToPathName(`/`);
    fetch('/logout', { method: 'POST' }).then(
      () => window.location.reload(), // stay at the same url
    );
  };

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 755 });
  }

  render() {
    const { clearStateNotifications, handleDrawerToggle, logout } = this;
    const {
      openMenu,
      openNotif,
      anchorEl,
      isDesktop,
      isHoveredMenu,
      notificationsLoading,
      notificationError,
    } = this.state;
    const {
      currentUser,
      classes,
      notifications,
      messageBadgeAmount,
      showMessageBadge,
      showProfileBadge,
      profileLabel,
      isTutorContext,
      width,
      isDualProfile,
      isStudentProfile,
      isAdmin,
    } = this.props;

    const open = Boolean(anchorEl);
    const openMenus = open && openMenu;
    const openNotifs = open && openNotif;
    const notificationsAmount = notifications ? notifications.length : 0;
    const showWarningBanner = global.isInvalidBrowser;

    const drawer = currentUser
      ? loggedInDrawerOptions(
          currentUser,
          classes,
          handleDrawerToggle,
          logout,
          showMessageBadge,
          messageBadgeAmount,
          isTutorContext,
          profileLabel,
          isDualProfile,
          this.handleContextClick,
          this.editProfile,
          isStudentProfile,
          isAdmin,
        )
      : loggedOutDrawerOptions(classes, handleDrawerToggle);

    const notificationButton =
      notificationsAmount > 0 ? (
        <Fragment>
          <Button
            aria-owns={open ? 'fade-menu' : null}
            aria-haspopup="true"
            className={classes.button}
            onClick={this.toggleOpenNotif}
            color="inherit"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Badge
                badgeContent={notificationsAmount}
                color="secondary"
                className={classes.BadgeRed}
              >
                <NotificationsIcon className={classes.muiIconBell} />
              </Badge>
              {width !== 'xs' && (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.iconLabel} variant="body2">
                    Notifications
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Button>
          <Menu
            className={classes.notificationList}
            id="fade-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            disableAutoFocusItem
            open={openNotifs}
            onClose={this.handleClose}
            TransitionComponent={Fade}
          >
            {notificationsLoading && (
              <MenuItem
                key="no_notification"
                button={false}
                className={classes.loadingNotifications}
              >
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  justify="center"
                >
                  <CircularProgress />
                </Grid>
              </MenuItem>
            )}
            {!notificationsLoading &&
              notificationsAmount > 0 &&
              [
                <ListItem key="no_notification" button={false}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    <Box flexGrow={1} flexShrink={1}>
                      <Typography variant="h6">Recent Notifications</Typography>
                      {notificationError && (
                        <FormHelperText error>
                          There was an error clearing your notifications.
                          Please, try again.
                        </FormHelperText>
                      )}
                    </Box>
                    <Box flexShrink={0}>
                      <Button
                        color="primary"
                        onClick={clearStateNotifications(this.props.client)}
                      >
                        Clear all
                      </Button>
                    </Box>
                  </Box>
                </ListItem>,
              ].concat(
                notifications.map(notification => (
                  <div>
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                    />
                    <Divider />
                  </div>
                )),
              )}
            {!notificationsLoading &&
              notificationsAmount === 0 && (
                <MenuItem>No pending notifications!</MenuItem>
              )}
          </Menu>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            aria-owns={open ? 'fade-menu' : null}
            aria-haspopup="true"
            className={classes.button}
            onClick={this.toggleOpenNotif}
            color="inherit"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={classes.notificationContainer}
            >
              <NotificationsIcon className={classes.muiIconBell} />
              {width !== 'xs' && (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.iconLabel} variant="body2">
                    Notifications
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Button>
          <Menu
            className={classes.notificationList}
            id="fade-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            disableAutoFocusItem
            open={openNotifs}
            onClose={this.handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem>No pending notifications!</MenuItem>
          </Menu>
        </Fragment>
      );

    const mobileHamburgerIcon = (
      <div className={classes.sectionMobile}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Button onClick={this.handleDrawerToggle}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <img src={hamburgerIconImage} height="20" alt="Menu" />
                </Grid>
              </Button>
            </Box>
          </Box>
          <Link
            to="/"
            className={classes.logoLink}
            onClick={this.handleDrawerClose}
          >
            <img
              className={classes.noAuthLogo}
              src={isTutorContext ? tutorSecondaryLogo : tutorPrimaryLogo}
              alt="Tutorfly"
            />
          </Link>
          {currentUser && <Box>{notificationButton}</Box>}
        </Box>
      </div>
    );

    const desktopToolbarOptions = currentUser ? (
      <div className={this.props.classes.headerContainer}>
        <div className={classes.sectionDesktop}>
          <Box
            display="flex"
            style={{ width: '100%', maxWidth: '1280px', margin: 'auto' }}
          >
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Box>
                <Button
                  onClick={() => navigateToSessions()}
                  className={classes.button}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <img
                      className={classes.logo}
                      src={
                        isTutorContext ? tutorSecondaryLogo : tutorPrimaryLogo
                      }
                      alt="Tutorfly"
                    />
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    />
                  </Grid>
                </Button>
              </Box>
            </Box>
            {!isTutorContext &&
              (SHOW_REQUEST_A_TUTOR_FORM || !currentUser) && (
                <Box>
                  <Button
                    aria-owns={open ? 'search-menu' : null}
                    aria-haspopup="true"
                    className={classes.button}
                    onClick={() => navigateToPathName('/request-a-tutor')}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      className={classes.headerIconContainer}
                    >
                      <Request className={classes.muiIcon} />
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography
                          className={classes.iconLabel}
                          variant="body2"
                        >
                          Request a Tutor
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Box>
              )}
            <Box>
              {isTutorContext && (
                <Button
                  aria-owns={open ? 'search-menu' : null}
                  aria-haspopup="true"
                  className={classes.button}
                  onClick={() => navigateToPathName('/tutor-tools')}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.headerIconContainer}
                  >
                    <BuildRoundedIcon className={classes.muiIcon} />
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Typography className={classes.iconLabel} variant="body2">
                        Find Clients
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              )}
              {!isTutorContext && (
                <Button
                  aria-owns={open ? 'search-menu' : null}
                  aria-haspopup="true"
                  className={classes.button}
                  component={Link}
                  to="/search"
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.headerIconContainer}
                  >
                    <SearchRoundedIcon className={classes.muiIcon} />
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Typography className={classes.iconLabel} variant="body2">
                        Find a Tutor
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={() => navigateToSessions()}
                className={classes.button}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.headerIconContainer}
                >
                  <SessionsIcon className={classes.muiIcon} />
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography className={classes.iconLabel} variant="body2">
                      Sessions
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => navigateToInbox()}
                className={classes.button}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  className={classes.headerIconContainer}
                >
                  <Badge
                    invisible={!showMessageBadge}
                    color="secondary"
                    badgeContent={messageBadgeAmount}
                  >
                    <InboxIcon className={classes.muiIcon} />
                  </Badge>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography className={classes.iconLabel} variant="body2">
                      Inbox
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Box>

            <Box>{notificationButton}</Box>

            <Box>
              <Button
                aria-owns={open ? 'fade-menu' : null}
                aria-haspopup="true"
                className={classes.button}
                onClick={this.toggleOpenMenu}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Badge
                    invisible={!showProfileBadge}
                    color="error"
                    badgeContent="!"
                  >
                    <ProfileAvatar
                      firstName={
                        currentUser ? currentUser.profile.firstName : ''
                      }
                      lastName={currentUser ? currentUser.profile.lastName : ''}
                      src={
                        currentUser
                          ? currentUser.profile.profilePicture.src
                          : ''
                      }
                      size={AVATAR_STYLE_HEADER_BAR}
                    />
                  </Badge>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.profileLabelContainer}
                  >
                    <Typography className={classes.iconLabel} variant="body2">
                      Me
                    </Typography>
                    <KeyboardDownArrowIcon
                      className={classes.profileDropdown}
                    />
                  </Grid>
                </Grid>
              </Button>
              <Menu
                className={classes.profileDropdownMenu}
                id="fade-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
                disableAutoFocusItem
                open={openMenus}
                onClose={this.handleClose}
                TransitionComponent={Fade}
              >
                <div>
                  <MenuItem
                    className={classes.profileMenuItem}
                    onClick={this.editProfile}
                    disableRipple
                  >
                    <ListItemText
                      primary={
                        <Fragment>
                          <Grid
                            fullWidth
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <ProfileAvatar
                                firstName={
                                  currentUser
                                    ? currentUser.profile.firstName
                                    : ''
                                }
                                lastName={
                                  currentUser
                                    ? currentUser.profile.lastName
                                    : ''
                                }
                                src={
                                  currentUser
                                    ? currentUser.profile.profilePicture.src
                                    : ''
                                }
                                size={AVATAR_STYLE_XS}
                              />
                            </Grid>
                            <Grid
                              className={classes.profileDescriptionContainer}
                              item
                              xs
                              container
                              direction="column"
                              justify="center"
                              alignItems="flex-start"
                              wrap="nowrap"
                            >
                              <Typography
                                className={classes.nameLabel}
                                variant="subtitle1"
                              >{`${currentUser.profile.firstName} ${
                                currentUser.profile.lastName
                              }`}</Typography>
                              <Typography
                                variant="body2"
                                className={classes.profileDescriptionLabel}
                              >
                                {`${profileLabel} on Tutorfly`}
                              </Typography>
                            </Grid>
                          </Grid>
                          {this.props.isDualProfile && (
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Chip
                                icon={<SwapCallsIcon />}
                                label={`Switch to ${
                                  isTutorContext ? 'client' : 'tutor'
                                } mode`}
                                onClick={this.handleContextClick}
                                variant="outlined"
                                className={classes.switchChipStyle}
                              />
                            </Grid>
                          )}
                        </Fragment>
                      }
                    />
                  </MenuItem>
                  <Divider />
                </div>
                <div>
                  <MenuItem button onClick={this.accountSettings}>
                    <Badge
                      invisible={!showProfileBadge}
                      color="error"
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'top',
                      }}
                      badgeContent="!"
                      className={classes.payoutTitle}
                    >
                      <ListItemIcon className={classes.muiListIcon}>
                        <AccountSettings />
                      </ListItemIcon>
                    </Badge>
                    <ListItemText
                      className={classes.profileListItemText}
                      primary={
                        <Typography className={classes.notificationHeaderText}>
                          <b>Account Settings</b>
                        </Typography>
                      }
                    />
                  </MenuItem>
                  <Divider />
                </div>
                {isTutorContext && (
                  <div>
                    <MenuItem button onClick={this.navigateTutorReferTutor}>
                      <ListItemIcon className={classes.muiListIcon}>
                        <Referral />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.profileListItemText}
                        primary={
                          <Typography
                            className={classes.notificationHeaderText}
                          >
                            <b>Refer Tutors</b>
                          </Typography>
                        }
                      />
                    </MenuItem>
                    <Divider />
                  </div>
                )}
                {isTutorContext && (
                  <div>
                    <MenuItem button onClick={this.navigateTutorReferClient}>
                      <ListItemIcon className={classes.muiListIcon}>
                        <Referral />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.profileListItemText}
                        primary={
                          <Typography
                            className={classes.notificationHeaderText}
                          >
                            <b>Refer Clients</b>
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2">
                            Each client you refer gets $20.
                          </Typography>
                        }
                      />
                    </MenuItem>
                    <Divider />
                  </div>
                )}
                {!isTutorContext && (
                  <div>
                    <MenuItem button onClick={this.referFriends}>
                      <ListItemIcon className={classes.muiListIcon}>
                        <Referral />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.profileListItemText}
                        primary={
                          <Typography
                            className={classes.notificationHeaderText}
                          >
                            <b>Refer Friends</b>
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="body2"
                            className={classes.secondary}
                          >
                            Get $20 for each client you refer.
                          </Typography>
                        }
                      />
                    </MenuItem>
                    <Divider />
                  </div>
                )}
                {isStudentProfile &&
                  !isTutorContext && (
                    <div>
                      <MenuItem button onClick={this.navBecomeATutor}>
                        <ListItemIcon className={classes.muiListIcon}>
                          <CastForEducationIcon />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.profileListItemText}
                          primary={
                            <Typography
                              className={classes.notificationHeaderText}
                            >
                              <b>Become a Tutor</b>
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              className={classes.secondary}
                            >
                              Earn $25+ per hour
                            </Typography>
                          }
                        />
                      </MenuItem>
                      <Divider />
                    </div>
                  )}
                {isAdmin && (
                  <div>
                    <MenuItem button onClick={this.navAdminPanel}>
                      <ListItemIcon className={classes.muiListIcon}>
                        <LensRoundedIcon />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.profileListItemText}
                        primary={
                          <Typography
                            className={classes.notificationHeaderText}
                          >
                            <b>Admin Panel</b>
                          </Typography>
                        }
                      />
                    </MenuItem>
                    <Divider />
                  </div>
                )}
                <div>
                  <MenuItem button onClick={this.logout} to="/">
                    <ListItemIcon className={classes.muiListIcon}>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.profileListItemText}
                      primary={
                        <Typography className={classes.notificationHeaderText}>
                          <b>Logout</b>
                        </Typography>
                      }
                    />
                  </MenuItem>
                </div>
              </Menu>
            </Box>
          </Box>
        </div>
      </div>
    ) : (
      <div className={this.props.classes.headerContainer}>
        <div className={classes.sectionDesktop}>
          {
            <Link
              to="/"
              className={classes.logoLink}
              onMouseEnter={this.handleMenuLeave}
            >
              <Box display="flex" alignItems="center">
                <img
                  className={classes.noAuthLogo}
                  src={tutorPrimaryLogo}
                  alt="Tutorfly"
                />
              </Box>
            </Link>
          }
          <div className={classes.grow} />

          {this.state.squidexPages.map(page => (
            <Button
              key={page.id}
              to={`/${page.flatData.slug}`}
              component={Link}
              color="inherit"
              className={classes.button}
              onMouseEnter={this.handleMenuLeave}
            >
              {page.flatData.menuTitle}
            </Button>
          ))}

          {/* <Button to="/home" component={Link} color="inherit">
            Squidex Test Page
          </Button>

          <Button
            to="/about-us/our-story"
            component={Link}
            color="inherit"
            className={`${classes.button} ${
              isHoveredMenu === 'aboutUs' ? classes.buttonActive : ''
            }`}
            onMouseEnter={() => this.handleMenuHover('aboutUs')}
            onMouseLeave={() => this.handleMenuHover('aboutUs')}
          >
            About Us
          </Button>

          <Button
            to="/schools"
            component={Link}
            color="inherit"
            className={`${classes.button} ${
              isHoveredMenu === 'forSchools' ? classes.buttonActive : ''
            }`}
            onMouseEnter={() => this.handleMenuHover('forSchools')}
            onMouseLeave={() => this.handleMenuHover('forSchools')}
          >
            Tutoring for Schools
          </Button>

          <Button
            to="/marketplace"
            component={Link}
            color="inherit"
            className={`${classes.button} ${
              isHoveredMenu === 'forFamilies' ? classes.buttonActive : ''
            }`}
            onMouseEnter={() => this.handleMenuHover('forFamilies')}
            onMouseLeave={() => this.handleMenuHover('forFamilies')}
          >
            Tutoring for Families
          </Button> */}

          {/* <Button
            to="/apply"
            component={Link}
            color="inherit"
            className={classes.button}
            onMouseEnter={this.handleMenuLeave}
          >
            Become a Tutor
          </Button> */}

          {/* <Button
            to="/contact"
            component={Link}
            color="inherit"
            className={classes.button}
            onMouseEnter={this.handleMenuLeave}
          >
            Contact Us
          </Button> */}

          <Button
            style={{ marginLeft: '1em' }}
            to="/login"
            component={Link}
            color="inherit"
            className={classes.loginButton}
            onMouseEnter={this.handleMenuLeave}
          >
            Login
          </Button>

          <Button
            style={{ marginLeft: '1em' }}
            to="/signup"
            component={Link}
            color="inherit"
            className={classes.signupButton}
            onMouseEnter={this.handleMenuLeave}
          >
            Sign up
          </Button>
        </div>

        {['aboutUs', 'forSchools', 'forFamilies'].map(menuType => (
          <div
            key={menuType}
            className={classes.blueNavPosition}
            style={{ display: isHoveredMenu === menuType ? 'block' : 'none' }}
            onMouseEnter={() => this.handleMenuHover(menuType)}
            onMouseLeave={this.handleMenuLeave}
          >
            <BlueNav menuType={menuType} />
          </div>
        ))}
      </div>
    );

    const warningBanner = (
      <div className={classes.warnOldBrowser}>
        <div className={classes.warningContentContainer}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningIcon className={classes.warningIcon} />
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="baseline"
            >
              <Typography className={classes.warningHeader} variant="h6">
                {`Please note that Tutorfly no longer supports old versions of Safari.`}
              </Typography>
              <Typography className={classes.footerText}>
                {
                  'We recommend upgrading your browser to the latest version of '
                }
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Google Chrome</b>
                </a>
                {', '}
                <a
                  href="https://www.mozilla.org/en-US/firefox/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Firefox</b>
                </a>
                {', or '}
                <a
                  href="https://support.apple.com/en-us/HT204416"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Safari</b>
                </a>
              </Typography>
            </Box>
            <div style={{ display: 'flex' }}>
              <a
                style={{ textDecoration: 'none' }}
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="contained" className={classes.warningButton}>
                  Get Chrome
                </Button>
              </a>
            </div>
          </Box>
        </div>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} elevation={1}>
          {showWarningBanner && warningBanner}
          <SetupNotifications client={this.props.client}>
            <Toolbar>
              {mobileHamburgerIcon}
              {desktopToolbarOptions}
            </Toolbar>
          </SetupNotifications>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: !isDesktop, // Better open performance on mobile.
            }}
          >
            <Toolbar>
              {mobileHamburgerIcon}
              {desktopToolbarOptions}
            </Toolbar>
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Header.propTypes = {
  client: PropTypes.object.isRequired, // eslint-disable-line
  currentUser: PropTypes.object, // eslint-disable-line
  logoutUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  notifications: PropTypes.array.isRequired, // eslint-disable-line
  messageBadgeAmount: PropTypes.number.isRequired,
  clearNotifications: PropTypes.func.isRequired, // eslint-disable-line
  showMessageBadge: PropTypes.bool.isRequired, // eslint-disable-line
  showProfileBadge: PropTypes.bool.isRequired, // eslint-disable-line
  width: PropTypes.string.isRequired, // eslint-disable-line
  profileLabel: PropTypes.string.isRequired, // eslint-disable-line
  isTutorContext: PropTypes.bool.isRequired, // eslint-disable-line
  switchToTutor: PropTypes.func.isRequired,
  switchToClient: PropTypes.func.isRequired,
  isDualProfile: PropTypes.bool.isRequired,
  isStudentProfile: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  currentUser: null,
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  clearNotifications: () => dispatch(clearNotifications()),
  switchToTutor: () => dispatch(switchToTutor()),
  switchToClient: () => dispatch(switchToClient()),
});

const mapStateToProps = state => {
  const { currentUser, notifications } = state.subscriptions;
  const { messageBadgeAmount } = state.subscriptions;
  let showMessageBadge = false;
  let showProfileBadge = false;
  let profileLabel = '';
  let isTutorContext = false;
  let isDualProfile = false;
  let isStudentProfile = false;
  let isAdmin = false;

  if (currentUser) {
    const { userType } = state.dashboard;
    isTutorContext =
      currentUser.profile.profileTypes.includes(ProfileType.TUTOR) &&
      userType === ProfileType.TUTOR;
    showMessageBadge = messageBadgeAmount > 0 && isTutorContext;
    isDualProfile =
      currentUser.profile.profileTypes.includes(ProfileType.TUTOR) &&
      currentUser.profile.profileTypes.includes(ProfileType.STUDENT_OR_PARENT);
    isAdmin = currentUser.profile.profileTypes.includes(ProfileType.ADMIN);
    if (isTutorContext) {
      const { isOnboarded } = currentUser.profile;
      showProfileBadge = !isOnboarded;
      profileLabel = 'Tutor';
    } else {
      const { clientProfileType } = currentUser.profile;
      profileLabel = isNotEmpty(clientProfileType)
        ? capitalizeFirstLetter(clientProfileType)
        : 'Client';
      isStudentProfile = clientProfileType === 'STUDENT';
      const hasPaymentMethod = currentUser.profile.creditCardData.length > 0;
      showProfileBadge = !hasPaymentMethod;
    }
  }

  return {
    currentUser,
    notifications,
    messageBadgeAmount,
    showMessageBadge,
    showProfileBadge,
    profileLabel,
    isTutorContext,
    isDualProfile,
    isStudentProfile,
    isAdmin,
  };
};

export default compose(
  withClient,
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles,
)(Header);
