import React, { useState } from 'react';

export default function withFocusable(WrappedComponent) {
  const hoc = props => {
    const [isFocused, setIsFocused] = useState(false);
    let timeoutID = null;

    const blurView = () => {
      timeoutID = setTimeout(() => {
        if (isFocused) {
          setIsFocused(false);
        }
      }, 0);
    };

    const onBlur = () => {
      blurView();
    };

    const onFocus = () => {
      clearTimeout(timeoutID);
      if (!isFocused) {
        setIsFocused(true);
      }
    };

    return (
      <div onBlur={onBlur} onFocus={onFocus}>
        <WrappedComponent
          isFocused={isFocused}
          blurParentView={blurView}
          {...props}
        />
      </div>
    );
  };
  return hoc;
}
