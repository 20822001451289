import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const SearchTabs = withStyles(({ breakpoints }) => ({
  root: {
    borderRadius: 10,
    minHeight: 44,
  },
  flexContainer: {
    display: 'inline-flex',
    position: 'relative',
    zIndex: 1,
  },
  scroller: {
    [breakpoints.up('md')]: {
      padding: '0 8px',
    },
  },
  indicator: {
    top: 3,
    bottom: 3,
    right: 3,
    height: 'auto',
    background: 'none',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 4,
      right: 4,
      bottom: 0,
      borderRadius: 20,
      backgroundColor: '#1890ff',
    },
  },
}))(props => <Tabs {...props} />);

export default SearchTabs;
