/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { TUTORS_BY_COURSE_QUERY } from 'shared/constants/gql-constants';
import {
  RESET_SEARCH,
  SET_COURSE,
  SET_TUTORS,
  UPDATE_SEARCH_PAGE_STATE,
  SET_LOCATION_FILTER,
  SET_LOCATION_DESCRIPTION,
  SET_STUDENT_GRADE_FILTER,
  SET_IS_LOCAL_SEARCH,
  SEARCH_LOADING,
  SEARCH_NEXT_PAGE_LOADING,
  SET_TUTOR_BY_COURSE_ERROR,
} from '../types';

export const resetSearch = () => dispatch => {
  dispatch({ type: RESET_SEARCH });
};

export const setTutors = (localTutors, onlineTutors) => async dispatch => {
  dispatch({ type: SET_TUTORS, payload: { localTutors, onlineTutors } });
};

export const setLoadingState = (isNextPage, loading) => dispatch => {
  if (isNextPage) {
    dispatch({
      type: SEARCH_NEXT_PAGE_LOADING,
      payload: {
        nextPageLoading: loading,
      },
    });
  } else {
    dispatch({
      type: SEARCH_LOADING,
      payload: {
        isLoading: loading,
      },
    });
  }
};

export const retrieveTutors = (course, client, isNextPage) => async (
  dispatch,
  getState,
) => {
  let {
    tutorSearchV2: {
      localTutors,
      onlineTutors,
      localPageNumber,
      onlinePageNumber,
      exhaustedLocalTutors,
      exhaustedOnlineTutors,
    },
  } = getState();

  const { zip, studentGradeLevel } = getState().tutorSearchV2;
  dispatch({
    type: SET_IS_LOCAL_SEARCH,
    payload: {
      isLocalSearch: zip.length === 5,
    },
  });

  dispatch(setLoadingState(isNextPage, true));

  client
    .query({
      query: TUTORS_BY_COURSE_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        input: course,
        localPageNumber,
        onlinePageNumber,
        zip,
        studentGradeLevel,
      },
    })
    .then(data => {
      // Get next page of online tutor results from the servers
      const nextLocalTutorsPage = data.data.searchTutorsByCourse.localTutors;
      const nextOnlineTutorsPage = data.data.searchTutorsByCourse.onlineTutors;

      // Set flags that determine whether we have exhausted our lists of tutors
      exhaustedLocalTutors = nextLocalTutorsPage.length < 10;
      exhaustedOnlineTutors = nextOnlineTutorsPage.length < 10;

      localTutors = localTutors.concat(nextLocalTutorsPage);
      onlineTutors = onlineTutors.concat(nextOnlineTutorsPage);

      dispatch(setTutors(localTutors, onlineTutors, client));
      dispatch(setLoadingState(isNextPage, false));

      if (!exhaustedLocalTutors) {
        localPageNumber += 1;
      } else if (exhaustedLocalTutors && !exhaustedOnlineTutors) {
        onlinePageNumber += 1;
      }
      dispatch({
        type: SET_TUTOR_BY_COURSE_ERROR,
        payload: {
          error: false,
        },
      });

      dispatch({
        type: UPDATE_SEARCH_PAGE_STATE,
        payload: {
          localPageNumber,
          onlinePageNumber,
          exhaustedLocalTutors,
          exhaustedOnlineTutors,
        },
      });
    })
    .catch(e => {
      console.error(e);
      dispatch(setLoadingState(isNextPage, false));
      dispatch({
        type: SET_TUTOR_BY_COURSE_ERROR,
        payload: {
          error: true,
        },
      });
    });
};

export const setCourse = (course, client) => async (dispatch, getState) => {
  dispatch({
    type: SET_COURSE,
    payload: {
      courseSelected: course,
    },
  });

  if (course) {
    dispatch(resetSearch());
    dispatch(retrieveTutors(course, client, false));
  } else {
    dispatch(resetSearch());
  }
};

export const setLocationDescription = locationDescription => async dispatch => {
  dispatch({
    type: SET_LOCATION_DESCRIPTION,
    payload: {
      locationDescription,
    },
  });
};

export const setLocationFilter = (newZip, client) => async (
  dispatch,
  getState,
) => {
  let {
    tutorSearchV2: {
      zip,
      // eslint-disable-next-line prefer-const
      courseSelected,
    },
  } = getState();

  zip = newZip;

  dispatch({
    type: SET_LOCATION_FILTER,
    payload: {
      zip,
    },
  });

  if (zip && zip.length === 5) {
    dispatch(resetSearch());
    dispatch(retrieveTutors(courseSelected, client, false));
  } else if (zip && zip.length < 5) {
    dispatch(resetSearch());
  }
};

export const setStudentGradeFilter = (newStudentGradeLevel, client) => async (
  dispatch,
  getState,
) => {
  // eslint-disable-next-line prefer-const
  let { tutorSearchV2: { studentGradeLevel, courseSelected } } = getState();

  studentGradeLevel = newStudentGradeLevel;

  dispatch({
    type: SET_STUDENT_GRADE_FILTER,
    payload: {
      studentGradeLevel,
    },
  });

  dispatch(resetSearch());
  dispatch(retrieveTutors(courseSelected, client, false));
};
