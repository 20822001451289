import React from 'react';
import PropTypes from 'prop-types';

const RichText = ({ content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
);

RichText.propTypes = {
  content: PropTypes.string.isRequired,
};

export default RichText;
