import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// import { size } from 'lodash';
import * as StyleConstants from 'shared/constants/styleConstants';

const coreTheme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: StyleConstants.tutorflyBlue,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: StyleConstants.tutorflyOrange,
    },
    white: '#fff',
    // error: will use the default color
  },
  typography: {
    h1: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#333',
    },
    h2: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#444',
    },
    h3: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#444',
    },
    h4: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#444',
    },
    h5: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#555',
    },
    h6: {
      fontFamily: 'Raleway',
      fontWeight: 700,
      color: '#555',
      lineHeight: 1.15,
    },
    xsmall: {
      fontWeight: 500,
      fontFamily: 'Raleway',
      color: '#555',
      fontSize: '11px',
    },
    subtitle1: {
      fontWeight: 500,
      fontFamily: 'Raleway',
      color: '#555',
    },
    subtitle2: {
      fontWeight: 700,
      fontFamily: 'Raleway',
      color: '#555',
    },
    body1: {
      fontWeight: 'normal',
      fontFamily: 'Raleway',
      color: '#333',
    },
    body2: {
      fontWeight: 500,
      fontFamily: 'Raleway',
      color: '#333',
    },
    small: {
      fontSize: '11px',
    },
    button: {
      fontFamily: 'Raleway',
      fontWeight: 500,
      fontSize: '17px',
      letterSpacing: '.05em',
      textTransform: 'none',
    },
    caption: {
      fontFamily: '"Raleway"',
    },
    overline: {
      fontFamily: '"Raleway"',
      color: '#555',
    },
  },
  overrides: {
    MuiInputAdornment: {
      root: {
        height: 'auto',
        display: 'flow-root',
        maxHeight: 'none',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: StyleConstants.buttonBlue,
      },
      containedSecondary: {
        backgroundColor: StyleConstants.tutorflyOrange,
      },
    },
    MuiCheckbox: {
      checked: {},
      root: {
        color: StyleConstants.buttonBlue,

        '&$checked': {
          color: StyleConstants.buttonBlue,
        },
      },
    },
    MuiSelect: {
      icon: {
        paddingRight: '8px',
      },
    },
  },
});
const responsiveTheme = responsiveFontSizes(coreTheme);

export default responsiveTheme;

export const multiSelectThemeOverride = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiInputAdornment: {
        root: {
          height: 'auto',
          display: 'flow-root',
          maxHeight: 'none',
        },
      },
      MuiOutlinedInput: {
        root: {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(0.8),
        },
        input: {
          paddingTop: theme.spacing(0.8),
          paddingBottom: theme.spacing(1.25),
        },
      },
    },
  });
