import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import SecondaryButtonV3 from 'shared/components/Buttons/SecondaryButtonV3';
import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SearchTabs from 'shared/components/tabs/SearchTabs';
import SearchTab from 'shared/components/tab/SearchTab';

const useStyles = makeStyles(theme => ({
  summaryText: {
    margin: '0px 6px',
    fontWeight: 300,
  },
  priceWrapper: {
    marginTop: theme.spacing(3),
  },
  tabSection: {
    marginBottom: theme.spacing(6),
  },
  gridWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  commitmentText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    wordWrap: 'break-word',
    textAlign: 'center',
    fontWeight: 300,
  },
  button: {
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '.1em',
    height: '40px',
    margin: '0px auto',
    whiteSpace: 'pre',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.secondary.main,
    },
    paddingBottom: '40px',
  },
  closeButton: {
    color: 'white',
    display: 'block',
    fontSize: '28px',
    fontWeight: 'bold',
    marginLeft: 'auto',
    width: '50px',
  },
  image: {
    margin: '5px auto',
    height: '40px',
  },
  perText: {
    fontSize: '28px',
    letterSpacing: '.15em',
    margin: '10px auto',
    width: '100%',
  },
  root: {
    textAlign: 'center',
    paddingBottom: '60px',
  },
  explanationText: {
    margin: 'auto',
    width: '80%',
    wordWrap: 'break-word',
  },
  top: {
    height: 'auto',
    width: '100%',
  },
  bottom: {
    height: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  buttonContainer: {
    paddingTop: '20px',
    marginBottom: theme.spacing(8),
    maxWidth: '150px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  anotherButton: {
    marginBottom: '30px',
    color: 'white',
  },
  pricingIcon: {
    width: '50px',
    height: '50px',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
}));

function Pricing() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const classes = useStyles();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.priceWrapper}>
          <div className={classes.tabSection}>
            <SearchTabs
              value={tabIndex}
              onChange={handleChangeTab}
              aria-label="Search Type"
              variant="standard"
            >
              <SearchTab label="Clients" />
              <SearchTab label="Tutors" />
            </SearchTabs>
          </div>
        </div>
        <div className={classes.priceWrapper}>
          {tabIndex === 1 && (
            <Typography variant="h2">Earn $25+ per hour</Typography>
          )}
          {tabIndex === 0 && (
            <Typography variant="h2">$25+ per hour</Typography>
          )}
        </div>

        {tabIndex === 0 && (
          <Typography variant="h5">for students at all levels</Typography>
        )}
        {tabIndex === 1 && (
          <Typography variant="h5">
            by teaching students at all levels
          </Typography>
        )}
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.buttonContainer}
        >
          {tabIndex === 0 && (
            <SecondaryButtonV3 href="/find-a-tutor">
              Find a Tutor
            </SecondaryButtonV3>
          )}
          {tabIndex === 1 && (
            <PrimaryButtonV3 href="/apply">Apply to Tutor</PrimaryButtonV3>
          )}
        </Grid>
      </div>

      <Container maxWidth="lg">
        <div className={classes.bottom}>
          <Typography variant="h5">What you get</Typography>
          <Grid
            container
            direction="row"
            className={classes.gridWrapper}
            spacing={3}
          >
            <Grid item md={4} xs={12}>
              {tabIndex === 0 && (
                <>
                  <CheckCircleIcon className={classes.pricingIcon} />
                  <Typography variant="body1" className={classes.summaryText}>
                    <strong>Peer tutors!</strong> Empathetic Tutorfly tutors
                    double as role models and understand what students are
                    struggling with.
                  </Typography>
                </>
              )}
              {tabIndex === 1 && (
                <>
                  <PeopleIcon className={classes.pricingIcon} />
                  <Typography variant="body1" className={classes.summaryText}>
                    <strong>Clients!</strong> We find clients for you and point
                    them your way when their needs align with your skills.
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {tabIndex === 0 && (
                <>
                  <AttachMoneyIcon className={classes.pricingIcon} />
                  <Typography variant="body1" className={classes.summaryText}>
                    <strong>Flexible pricing!</strong> Pay per session or for a
                    discounted tutoring package. 100% satisfaction guaranteed,
                    or your money back.
                  </Typography>
                </>
              )}
              {tabIndex === 1 && (
                <>
                  <EventNoteIcon className={classes.pricingIcon} />
                  <Typography variant="body1" className={classes.summaryText}>
                    <strong>Organizational tools!</strong> We provide a robust
                    scheduling system that lets you keep track of your upcoming
                    tutoring sessions at a glance.
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {tabIndex === 0 && (
                <>
                  <FavoriteBorderIcon className={classes.pricingIcon} />
                  {
                    <Typography variant="body1" className={classes.summaryText}>
                      <strong>Transparency!</strong> We charge clients a small
                      service fee of <strong>9.7%</strong> per session, which
                      helps us cover processing fees, hosting costs, and feature
                      development.
                    </Typography>
                  }
                </>
              )}
              {tabIndex === 1 && (
                <>
                  <AttachMoneyIcon className={classes.pricingIcon} />
                  <Typography variant="body1" className={classes.summaryText}>
                    <strong>Low service fees!</strong> We charge tutors a small
                    fee of <strong>9.7%</strong> per session, which helps us
                    cover processing fees, hosting costs, and the cost of
                    finding you clients.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {tabIndex === 0 && (
            <Grid container spacing={1} justify="center">
              <Grid item>
                <Typography variant="h5">Our commitment</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.commitmentText}>
                  We are committed to always delivering the highest quality
                  tutors at the lowest possible prices. At Tutorfly, there are
                  no introductory fees, and you are never required to purchase
                  more than one session at a time. Get started today!
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    </div>
  );
}

export default Pricing;
