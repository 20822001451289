import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    textColor: 'white',
  },
  chipText: {
    color: 'white',
  },
}));

function SearchChipSelected({ labelText, ...props }) {
  const classes = useStyles();
  return (
    <Chip
      className={classes.root}
      label={
        <Typography variant="subtitle1" className={classes.chipText}>
          {labelText}
        </Typography>
      }
      variant="default"
      color="primary"
      {...props}
      classes={{
        colorPrimary: classes.root,
        root: classes.root,
        icon: classes.root,
        label: classes.root,
      }}
    />
  );
}
SearchChipSelected.propTypes = {
  labelText: PropTypes.string.isRequired,
};

export default SearchChipSelected;
