// CardComponent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';

const CardComponent = ({ title, subtitle, text, button, image }) => {
  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-md tw-overflow-hidden tw-h-full tw-flex tw-flex-col tw-px-4 lg:tw-px-0">
      {image &&
        image.length > 0 && (
          <div
            className="tw-bg-cover tw-bg-center tw-h-72 tw-rounded-t-lg"
            style={{ backgroundImage: `url(${getImageUrl(image)})` }}
          />
        )}
      <div className="tw-p-6 tw-flex tw-flex-col tw-justify-between tw-flex-grow">
        <div>
          <h2 className="tw-text-2xl tw-font-bold tw-mb-2">{title}</h2>
          {subtitle && <p className="tw-font-bold tw-mb-2">{subtitle}</p>}
          <p className="tw-mb-4 tw-whitespace-pre-line">{text}</p>
        </div>
        {button && (
          <div className="tw-mt-auto">
            <Button
              text={button.text || 'Learn More'}
              link={button.link}
              color={button.color || 'orange'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

CardComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ),
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
};

CardComponent.defaultProps = {
  subtitle: '',
  image: [],
  button: null,
};

export default CardComponent;
