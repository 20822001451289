// ColumnsComponent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import DynamicComponent from './dynamicComponent';

const ColumnsComponent = ({ blocks, columns }) => {
  return (
    <div className="tw-mx-auto tw-py-12 tw-max-w-[1440px]">
      <div className={`columns-grid columns-grid-${columns}`}>
        {blocks.map((block, index) => (
          <DynamicComponent
            key={block.id || `block-col-${block.__typename}-${index}`}
            {...block}
          />
        ))}
      </div>
    </div>
  );
};

ColumnsComponent.propTypes = {
  columns: PropTypes.number.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      __typename: PropTypes.string.isRequired,
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
};

export default ColumnsComponent;
