import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  container: {
    display: 'flex',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: '120px',
  },
  deviceControl: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    minWidth: '120px',
  },
  select: {
    flexGrow: 1,
    background: 'white',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    background: 'white',
  },
  input: {
    background: 'white',
  },
  label: {
    fontSize: '1.15em',
    color: 'white',
    lineHeight: '0',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1rem',
    },
  },
});

export default withStyles(styles);
