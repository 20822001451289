import Filter from 'bad-words';

/**
 * Filter map that contains all foul language we want to detect.
 * See https://www.npmjs.com/package/bad-words for how to add
 * additional words/phrases to this filter map.
 */
const foulLanguageFilterMap = new Filter();

/**
 * Returns whether or not a string value contains profanity.
 * NOTE: There's an issue with export default where the client won't compile
 * properly when attempting to import a function/class that has been exported
 * using export default. I am disabling this linter error for now until
 * we figure out what the root cause of that error is.
 */
// eslint-disable-next-line import/prefer-default-export
export const isProfane = value =>
  foulLanguageFilterMap.isProfane(value.toLowerCase());
