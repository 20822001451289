/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/*
 * PrimaryInfo -> index.jsx
 *
 * Point-of-reference: Ryan
 * Purpose: Take user input on their first or last name and update the appropriate elements within the redux store
 * Location: Seen inside the ApplyToTutorForm on the landing page
 * Interacts-with: ApplyToTutor
 */

import React from 'react';
import phone from 'phone';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withClient from 'shared/components/ApolloClient/withClient';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputMask from 'react-input-mask';
import Typography from '@material-ui/core/Typography';
import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import { CircularProgress, FormHelperText } from '@material-ui/core';
// relevant actions
import { updatePersonalInfo } from 'shared/actions/applyToTutor';
import {
  dobInTutoringRange,
  isValidDob,
  isPhoneValid,
  checkValidEmail,
  isNameFieldValid,
  removeNonNumbers,
  isPasswordValid,
  getErrorMessage,
} from 'shared/utilities/utils';
import { CHECK_EMAIL_IN_USE_QUERY } from 'shared/constants/gql-constants';
import { navigateToPathName } from 'shared/utilities/navigation';
import withStyles from '../sharedStyles/applyFormSharedStyles';

class PrimaryInfo extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    updatePersonalInfo: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fNameValid: true,
      lNameValid: true,
      dobValid: true,
      dobInRange: true,
      phoneValid: true,
      emailValid: true,
      passwordValid: true,

      isFetching: false,
      errorAccountExists: false,
      errorAccountExistsHint: 'Your selected email or phone number is already taken by a user. Please specify a different email/phone number.',
    };
  }

  setPhoneNumber = e => {
    const phoneNumber = e.target.value;
    this.props.updatePersonalInfo('phoneNumber', phoneNumber);
  };

  allFieldsValid = () => {
    const {
      firstName,
      lastName,
      dob,
      phoneNumber,
      email,
      password,
    } = this.props;

    const fNameValid = isNameFieldValid(firstName.trim());
    const lNameValid = isNameFieldValid(lastName.trim());
    const dobValid = isValidDob(dob);
    const dobInRange = dobInTutoringRange(dob);
    const phoneValid = isPhoneValid(phoneNumber);
    const emailValid = checkValidEmail(email.trim());
    const passwordValid = isPasswordValid(password);

    this.setState({
      fNameValid,
      lNameValid,
      dobValid,
      dobInRange,
      phoneValid,
      emailValid,
      passwordValid,
    });

    return (
      fNameValid &&
      lNameValid &&
      dobInRange &&
      dobValid &&
      phoneValid &&
      passwordValid &&
      emailValid
    );
  };

  handleNextClick = async () => {
    if (!this.allFieldsValid()) {
      return;
    }

    const { phoneNumber, email } = this.props;

    this.setState({ isFetching: true })
    try {
      await this.props.client.query({
        fetchPolicy: 'network-only',
        query: CHECK_EMAIL_IN_USE_QUERY,
        variables: {
          email,
          phone: phone(phoneNumber, 'USA')[0],
        },
      });
      this.setState({ isFetching: false, errorAccountExists: false })
    } catch (error) {
      this.setState({
        isFetching: false,
        errorAccountExists: true,
        errorAccountExistsHint: `${getErrorMessage(error)} Please specify a different email or phone number.`,
      })
      return;
    }

    this.props.updatePersonalInfo('existingStudentFlow', false);
    navigateToPathName('/apply/core-subjects');
  };

  render = () => {
    const {
      classes,
      firstName,
      lastName,
      dob,
      phoneNumber,
      email,
      password,
    } = this.props;
    const {
      fNameValid,
      lNameValid,
      dobValid,
      dobInRange,
      phoneValid,
      emailValid,
      passwordValid,
      isFetching,
      errorAccountExists,
      errorAccountExistsHint,
    } = this.state;
    const rawPhoneNumber = removeNonNumbers(phoneNumber);

    return (
      <div className={classes.container}>
        <Grid alignContent="start" justify="start">
          <div className={classes.whiteContent}>
            <Typography className={classes.fieldPrompt} variant="body1" style={{color: 'white'}}>
              {'Please enter your full name.'}
            </Typography>
            <TextField
              id="firstName"
              placeholder="First name"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!fNameValid}
              helperText={!fNameValid ? 'Please enter a valid first name.' : ''}
              type="text"
              className={classes.textField}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
                'aria-label': 'First name',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                this.props.updatePersonalInfo('firstName', e.target.value)
              }
              value={firstName}
            />
            <TextField
              id="lastName"
              placeholder="Last name"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!lNameValid}
              helperText={!lNameValid ? 'Please enter a valid last name.' : ''}
              type="text"
              className={classes.textField}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
                'aria-label': 'Last name',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                this.props.updatePersonalInfo('lastName', e.target.value)
              }
              value={lastName}
            />

            <Typography className={classes.fieldPrompt} variant="body1" style={{color: 'white'}}>
              {'Please enter your birthday.'}
            </Typography>
            <TextField
              id="dob"
              placeholder="mm/dd/yyyy"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!dobValid || !dobInRange}
              helperText={
                !dobValid
                  ? 'Please use the format mm/dd/yyyy.'
                  : !dobInRange
                  ? 'You must be at least 14 years old.'
                  : ''
              }
              type="text"
              className={classes.textField}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
                'aria-label': 'Date of birth',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e =>
                this.props.updatePersonalInfo('dob', e.target.value)
              }
              value={dob}
            />

            <Typography className={classes.fieldPrompt} variant="body1" style={{color: 'white'}}>
              {`We will notify you via email and text with tutoring opportunities.`}
            </Typography>
            <InputMask
              mask={
                rawPhoneNumber.startsWith('1')
                  ? '9 (999) 999-9999'
                  : '(999) 999-9999'
              }
              value={phoneNumber}
              className={classes.textField}
              onChange={this.setPhoneNumber}
              maskChar={null}
            >
              {() => (
                <TextField
                  id="phone"
                  placeholder="Phone number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  className={classes.textField}
                  error={!phoneValid}
                  helperText={
                    !phoneValid ? 'Please enter a valid phone number.' : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                    'aria-label': 'Phone number',
                  }}
                />
              )}
            </InputMask>
            <TextField
              id="email"
              placeholder="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!emailValid}
              helperText={!emailValid ? 'Please enter a valid email.' : ''}
              type="text"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
                'aria-label': 'Email',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              onChange={e =>
                this.props.updatePersonalInfo('email', e.target.value)
              }
              value={email}
            />

            <div>
              <Typography className={classes.fieldPrompt} variant="body1" style={{color: 'white'}}>
                {'Please create a password for your Tutorfly account.'}
              </Typography>

              <TextField
                id="outlined-bare"
                placeholder="Set your password"
                value={password}
                className={classes.textField}
                onChange={e =>
                  this.props.updatePersonalInfo('password', e.target.value)
                }
                fullWidth
                error={!passwordValid}
                helperText={
                  !passwordValid ? 'Please enter a valid password.' : ''
                }
                margin="normal"
                variant="outlined"
                inputProps={{ 'aria-label': 'Set your password' }}
                type="password"
              />
            </div>

            <div className={classes.ctaContainer}>
              {errorAccountExists && <FormHelperText error className={classes.errorIndicator}>
                {errorAccountExistsHint}
              </FormHelperText>}
              {isFetching && (
                <Grid container justify="center" alignContent="center">
                  <CircularProgress />
                </Grid>
              )}
              {!isFetching && (
                <PrimaryButtonV3
                  size="large"
                  fullWidth
                  onClick={this.handleNextClick}
                >
                  Next
                </PrimaryButtonV3>
              )}
            </div>
          </div>
        </Grid>
      </div>
    );
  };
}

const mapStateToProps = state => {
  const {
    firstName,
    lastName,
    dob,
    phoneNumber,
    email,
    password,
  } = state.applyToTutor;
  return {
    firstName,
    lastName,
    dob,
    phoneNumber,
    email,
    password,
  };
};

export default compose(
  withStyles,
  withClient,
  connect(mapStateToProps, {
    updatePersonalInfo,
  }),
)(PrimaryInfo);
