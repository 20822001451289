/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TopWave from 'shared/assets/svg/wave-bottom-white.svg';

const MeettheteamComponent = ({ sectionTitle, teamMembers }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [hoverMemberId, setHoverMemberId] = useState(null);

  const getImageUrl = (member, isHover) => {
    const imageArray = isHover ? member.hoverImage : member.image;
    return imageArray && imageArray.length > 0 && imageArray[0].url
      ? imageArray[0].url
      : '';
  };

  useEffect(
    () => {
      const hoverImages = teamMembers.map(member => getImageUrl(member, true));
      hoverImages.forEach(src => {
        const img = new Image();
        img.src = src;
      });
    },
    [teamMembers],
  );

  const openMemberModal = member => {
    setSelectedMember(member);
    setOpenModal(true);
  };

  const closeMemberModal = () => {
    setSelectedMember(null);
    setOpenModal(false);
  };

  const handleKeyPress = (event, member) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openMemberModal(member);
    }
  };

  return (
    <div className="tw-bg-gray-100 tw-relative">
      <div className="tw-w-full">
        <img
          src={TopWave}
          className="tw-w-full tw-h-[185px] sm:tw-h-[115px]"
          alt=""
        />
      </div>
      <h1 className="tw-text-center tw-my-8">{sectionTitle}</h1>
      <div className="tw-max-w-7xl tw-mx-auto tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-8 tw-p-8 tw--mt-12 xl:tw-px-0">
        {teamMembers.map(member => (
          <div
            key={member.id || member.name}
            className="tw-flex tw-flex-col tw-items-center sm:tw-items-start tw-cursor-pointer"
            onClick={() => openMemberModal(member)}
            onKeyPress={e => handleKeyPress(e, member)}
            onMouseEnter={() => setHoverMemberId(member.id || member.name)}
            onMouseLeave={() => setHoverMemberId(null)}
            role="button"
            tabIndex={0}
          >
            <img
              src={
                hoverMemberId === (member.id || member.name)
                  ? getImageUrl(member, true)
                  : getImageUrl(member, false)
              }
              alt={member.name}
              className="tw-w-full tw-mb-6 tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-transform hover:tw-scale-105 md:tw-max-w-xs"
            />
            <h3 className="tw-font-bold tw-my-0 tw-text-center sm:tw-text-left">
              {member.name}
            </h3>
            <p className="tw-py-0 tw-text-center sm:tw-text-left">
              {member.title}
            </p>
          </div>
        ))}
      </div>
      {openModal &&
        selectedMember && (
          <div
            className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-70 tw-flex tw-justify-center tw-items-center tw-z-50"
            onClick={closeMemberModal}
            onKeyPress={e => e.key === 'Escape' && closeMemberModal()}
            role="button"
            tabIndex={0}
          >
            <div
              className="tw-bg-white tw-w-4/5 tw-max-w-3xl tw-p-5 tw-rounded-lg tw-shadow-lg"
              onClick={e => e.stopPropagation()}
              onKeyPress={e => e.stopPropagation()}
              role="dialog"
              aria-modal="true"
            >
              <div className="tw-flex tw-justify-end">
                <button
                  className="tw-text-2xl tw-bg-transparent tw-border-none tw-cursor-pointer"
                  onClick={closeMemberModal}
                >
                  ×
                </button>
              </div>
              <div className="tw-flex tw-flex-col md:tw-flex-row">
                <div className="tw-flex tw-flex-col md:tw-w-1/3 tw-mx-auto">
                  <img
                    src={getImageUrl(selectedMember, true)}
                    alt={selectedMember.name}
                    className="tw-w-full tw-max-w-xs"
                  />
                </div>
                <div className="tw-flex tw-flex-col tw-justify-center md:tw-w-2/3 md:tw-pl-6">
                  <h2 className="tw-mb-0">{selectedMember.name}</h2>
                  <p className="tw-py-0">{selectedMember.title}</p>
                  <p className="tw-whitespace-pre-line">
                    {selectedMember.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

MeettheteamComponent.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
      hoverImage: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default MeettheteamComponent;
