import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BottomWave from 'shared/assets/svg/rounded-wave-bottom-inverse-grey.svg';
import withStyles from './HeaderWhiteStyles';

const HeaderWhite = ({ title, breadcrumb, subtitle, link, classes }) => (
  <div>
    <div className={classes.pageTitleBackground}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        className={classes.pageTitleContainer}
      >
        <Typography className={classes.breadcrumb}>
          <a href="/" className={classes.breadcrumb}>
            Home
          </a>{' '}
          &gt;{' '}
          <a href={link} className={classes.breadcrumb}>
            {breadcrumb}
          </a>
        </Typography>
        <Typography variant="h2" className={classes.pageTitle}>
          {title}
        </Typography>
        <Typography variant="h5" style={{ color: '#444444' }}>
          {subtitle}
        </Typography>
      </Box>
    </div>
    <img className={classes.wave} src={BottomWave} width="100%" alt="" />
  </div>
);

HeaderWhite.propTypes = {
  classes: PropTypes.shape.isRequired,
  title: PropTypes.string,
  breadcrumb: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
};

HeaderWhite.defaultProps = {
  title: 'Tutoring for Families',
  breadcrumb: 'Information',
  subtitle: 'Connect with qualified tutors for in-person or online tutoring',
  link: '../../',
};

export default withStyles(HeaderWhite);
