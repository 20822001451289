import { withStyles } from '@material-ui/core/styles';
import { buttonBlue } from 'shared/constants/styleConstants';

const styles = theme => ({
  container: {
    margin: '0',
    maxWidth: '100%',
    textAlign: 'left',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
  whiteContent: {
    color: 'white',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0),
    },
  },
  formControlLabel: {
    fontSize: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
  errorIndicator: {
    marginBottom: theme.spacing(1),
  },
  ctaContainer: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    fontSize: 25,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  subHeader: {
    paddingBottom: theme.spacing(3),
    textAlign: 'left',
  },
  section: {
    textAlign: 'left',
  },
  paddedSection: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
  paymentSection: {
    textAlign: 'left',
    width: '100%',
  },
  radio: {
    width: '100%',
  },
  checkboxContainer: {
    alignItems: 'left',
    textAlign: 'left',
    paddingLeft: '40px',
  },
  checkbox: {
    width: '100%',
  },
  textField: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
  },
  bioTextField: {
    marginBottom: theme.spacing(4),
  },
  errorTextLabel: {
    marginBottom: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  resize: {
    fontSize: 17,
  },
  descriptionSpacing: {
    paddingBottom: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    height: 'auto',
  },

  gpaTextField: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  yearTextField: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  logoContainer: {
    alignItems: 'center',
    flex: 'none',
    marginTop: '-5px',
    marginBottom: '-15px',
  },
  tutorflyLogoMobile: {
    width: '60%',
    marginLeft: '20%',
    marginRight: '20%',
  },

  prefPaymentContainer: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: 'calc(100%-20px)',
  },
  prefPaymentDesc: {
    fontSize: 14,
    textAlign: 'left',
  },
  bioContainer: {
    marginBottom: theme.spacing(3),
  },
  fieldPrompt: {
    paddingTop: theme.spacing(3),
    textAlign: 'left',
    fontFamily: 'Raleway',
    fontWeight: 300,
    color: '#444',
    fontSize: '16px',
  },
  linkText: {
    color: buttonBlue,
    textDecoration: 'none',
    fontWeight: 500,
    fontFamily: 'Raleway',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
});

export default withStyles(styles);
