import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SHOW_REQUEST_A_TUTOR_FORM } from 'shared/constants/index';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  listContainer: {
    maxHeight: theme.spacing(50),
    overflowY: 'scroll',
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  ctaButton: {
    paddingTop: theme.spacing(4),
  },
}));

function CourseResultsListV2({
  setSelectedOption,
  courseOptions,
  loading,
  courseInput,
  setCourse,
  client,
  blurParentView,
  currentUser,
}) {
  const classes = useStyles();

  if (loading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={classes.gridContainer}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (courseInput.length === 0 && courseOptions.length === 0) {
    return (
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        className={classes.gridContainer}
      >
        <Typography variant="subtitle1">
          Please type a course or subject name in the search bar above.
        </Typography>
      </Grid>
    );
  }

  if (courseInput.length > 0 && courseOptions.length === 0) {
    return (
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        direction="column"
        className={classes.gridContainer}
      >
        {!SHOW_REQUEST_A_TUTOR_FORM &&
          !!currentUser && (
            <Typography variant="h6" className={classes.ctaButton} gutterBottom>
              No matching classes or subjects found.
            </Typography>
          )}
        {(SHOW_REQUEST_A_TUTOR_FORM || !currentUser) && (
          <div>
            <Typography variant="subtitle1">
              Don&apos;t see the class you&apos;re looking for? Submit a request
              and we&apos;ll see if any of our existing tutors can tutor the
              desired course.
            </Typography>
            <div className={classes.ctaButton}>
              <PrimaryButtonV3 size="large" href="/request-a-tutor">
                Request a Tutor
              </PrimaryButtonV3>
            </div>
          </div>
        )}
      </Grid>
    );
  }

  return (
    <List className={classes.listContainer}>
      {courseOptions.map(option => (
        <ListItem
          key={option.value}
          button
          onClick={() => {
            setCourse(option.label, client);
            setSelectedOption(option);
            blurParentView();
          }}
        >
          <ListItemText
            primary={option.label}
            secondary={option.description}
            primaryTypographyProps={{
              variant: 'subtitle1',
            }}
            secondaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}
CourseResultsListV2.propTypes = {
  setSelectedOption: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  courseOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  courseInput: PropTypes.string.isRequired,
  setCourse: PropTypes.func.isRequired,
  client: PropTypes.shape.isRequired,
  blurParentView: PropTypes.func.isRequired,
  currentUser: PropTypes.shape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(connect(mapStateToProps))(CourseResultsListV2);
