import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

export const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: '300px',
  },
  closeButton: {
    position: 'absolute',
    color: '#333',
    right: theme.spacing(0),
    top: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  header: {
    fontWeight: 'bold',
    color: '#333',
  },
  pickerRoot: {
    boxShadow:
      '0px 2px 4px -2px rgba(0,0,0,0.2), 0px 4px 5px -2px rgba(0,0,0,0.14), 0px 1px 10px -7px rgba(0,0,0,0.12)',
  },
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <div>
      <MuiDialogTitle
        disableTypography
        className={cx(classes.root, classes.pickerRoot)}
      >
        <Typography className={classes.header} variant="h5">
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </div>
  );
});

export const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
}))(MuiDialogActions);
