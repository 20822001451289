import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Link from 'shared/components/Link/Link';
import withStyles from '../marketplace/components/BlueNav/BlueNavStyles';

const BlueNav = ({ classes, subPages, currentSlug }) => {
  return (
    <div className={classes.blueNav}>
      <div className={classes.container}>
        {subPages.map(page => (
          <Button
            key={page.id}
            to={`/${page.flatData.slug}`}
            component={Link}
            color="inherit"
            className={`${classes.button} ${
              currentSlug === page.flatData.slug ? classes.buttonActive : ''
            }`}
          >
            {page.flatData.title}
          </Button>
        ))}
      </div>
    </div>
  );
};

BlueNav.propTypes = {
  classes: PropTypes.shape({
    blueNav: PropTypes.string,
    container: PropTypes.string,
    button: PropTypes.string,
    buttonActive: PropTypes.string,
  }).isRequired,
  subPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      flatData: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  currentSlug: PropTypes.string.isRequired,
};

export default withStyles(BlueNav);
