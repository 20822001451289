import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { buttonOrangeFocus } from 'shared/constants/styleConstants';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
    minWidth: '45px',
    textTransform: 'none',
    '&:focus': {
      backgroundColor: buttonOrangeFocus,
      outline: 0,
    },
    '&:hover': {
      backgroundColor: buttonOrangeFocus,
    },
  },
});

const SecondaryButtonV3 = props => (
  <Button
    disableElevation
    variant="contained"
    size="medium"
    color="secondary"
    className={props.classes.root}
    {...props}
  >
    {props.children}
  </Button>
);

SecondaryButtonV3.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
};

export default withStyles(styles)(SecondaryButtonV3);
