import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import SessionRequestForm from 'shared/components/RequestForm/SessionRequestForm';
import {
  truncateSigFig,
  generateDefaultTutorBio,
  deduplicateArrays,
} from 'shared/utilities/utils';
import { AVATAR_STYLE_SM } from 'shared/constants/index';
import StarIcon from '@material-ui/icons/StarRounded';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'shared/components/Link/Link';
import Button from '@material-ui/core/Button';
import ProfileAvatar from 'shared/components/ProfileAvatar/ProfileAvatar';
import TutorCardTabs from 'shared/components/tabs/TutorCardTabs';
import TutorCardTab from 'shared/components/tab/TutorCardTab';
import { navigateSignup } from 'shared/utilities/navigation';
import { warnAlert, warnAlertWithButton } from 'shared/utilities/alerts';
import { GET_REVIEWS_BY_PROFILE_ID } from 'shared/constants/gql-constants';
import withClient from 'shared/components/ApolloClient/withClient';
import {
  buttonOrangeFocus,
  buttonOrange,
} from 'shared/constants/styleConstants';
import useIsMobile from 'shared/hooks/useIsMobile';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(3),
    cursor: 'pointer',
  },
  mobileContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(3),
    cursor: 'pointer',
    width: 0.75,
  },
  contentContainer: {
    margin: theme.spacing(2),
  },
  mobileTextContainer: {
    marginRight: theme.spacing(2),
    width: '100%',
  },
  avatarContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  informationContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  mobileInformationContainer: {
    paddingBottom: theme.spacing(1),
  },
  tabsContainer: {
    display: 'flex',
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    borderLeftColor: theme.palette.divider,
    borderLeftWidth: 'thin',
    borderLeftStyle: 'solid',
    width: theme.spacing(30),
  },
  shortBioContainer: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(20),
    flexGrow: 1,
    flexDirection: 'column',
  },
  tallBioContainer: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(30),
    flexGrow: 1,
    flexDirection: 'column',
  },
  avatarInformation: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexWrap: 'nowrap',
  },
  mobileButton: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  vDivider: {
    color: '#444',
    height: '20px',
  },
  linkText: {
    color: theme.palette.primary.main,
    display: 'flex',
    textDecoration: 'none',
  },
  readMore: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: 'Raleway',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    boxShadow: 'none',
    backgroundColor: buttonOrange,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
    justifyContent: 'center',
    width: '100%',
    minWidth: '45px',
    borderRadius: '4px',
    textTransform: 'none',
    '&:focus': {
      backgroundColor: buttonOrangeFocus,
      outline: 0,
    },

    '&:hover': {
      backgroundColor: buttonOrangeFocus,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  profileIcon: {
    color: buttonOrange,
    paddingRight: theme.spacing(0.5),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipText: {
    color: '#FFFFFF',
  },
}));

function TutorCardV2({ client, tutor, currentUser }) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [requestSessionOpen, setRequestSessionOpen] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);
  const [submitDisabled, setSubmitDisabled] = React.useState('');
  const isMobile = useIsMobile();

  const fetchReviewsById = async () => {
    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_REVIEWS_BY_PROFILE_ID,
        variables: {
          tutorId: tutor.id,
        },
      });
      setReviews(data.getAllReviewsForTutorQuery);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    (async () => {
      fetchReviewsById();
    })();
  }, []);

  const setDisabled = disableSubmit => {
    setSubmitDisabled(disableSubmit);
  };

  const needToSignUp = () => {
    navigateSignup();
    warnAlert(
      'Please Sign In',
      `Please sign up or log in to Tutorfly before connecting with a Tutorfly tutor.`,
    );
  };

  const needToVerifyEmail = () => {
    warnAlertWithButton(
      'Please Verify your Email',
      `Please verify your email before connecting with a Tutorfly tutor.`,
      'Verify email',
      '/welcome',
    );
  };

  const handleContactClick = () => {
    if (currentUser && currentUser.email.verified) {
      setRequestSessionOpen(true);
    } else if (currentUser) {
      needToVerifyEmail();
    } else {
      needToSignUp();
    }
  };

  const hideModal = () => {
    setRequestSessionOpen(false);
  };

  const {
    firstName,
    lastName,
    profilePicture,
    hourlyWage,
    bio,
    profileId,
    avgReviewScore,
    school,
    hoursTutored,
    availabilityDesc,
  } = tutor.profile;
  const { id: tutorId } = tutor;
  const tutorBio = bio || generateDefaultTutorBio(tutor.profile);
  const numberReviews = reviews ? reviews.length : 0;
  const showReviewScore = truncateSigFig(avgReviewScore, 1);
  const shouldShowReviewScore = showReviewScore > 0 && numberReviews > 0;
  const showHoursTutored = truncateSigFig(parseFloat(hoursTutored), 1);
  const shouldShowHoursTutored = showHoursTutored > 0;
  const courses = tutor.profile.classes || [];
  const courseSubjects = courses.map(item => item.subject);
  const subjectList = deduplicateArrays([], courseSubjects);
  const shouldShowSubjectList = subjectList.length > 0;
  const lastElem = subjectList.pop() || '';
  const bioContainerStyle = shouldShowSubjectList
    ? classes.tallBioContainer
    : classes.shortBioContainer;
  const tutorAvailability =
    availabilityDesc ||
    `My Schedule is flexible! Please click on the orange button below to connect with me and we can discuss a time for our first tutoring session.`;

  return (
    <div>
      <Paper elevation={2}>
        {isMobile && (
          <Box
            display="flex"
            flexDirection="column"
            className={classes.container}
          >
            <Link
              to={`/u/${profileId}`}
              underline="none"
              openNewTab
              isInternalTutorflyLink
              className={classes.linkText}
              style={{ flexDirection: 'column' }}
            >
              <Box display="flex" flexDirection="row">
                <Box
                  flexDirection="column"
                  className={classes.mobileTextContainer}
                >
                  <Box>
                    <Typography variant="h6" style={{ lineHeight: 1 }}>
                      {school ? `${firstName} - ${school}` : `${firstName}`}
                    </Typography>
                  </Box>
                  <div>
                    {shouldShowReviewScore && (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <StarIcon className={classes.profileIcon} />
                        <Typography className={classes.boldFont} variant="h6">
                          {`${showReviewScore}`}
                        </Typography>
                        <Box p={1}>
                          <Divider
                            orientation="vertical"
                            className={classes.vDivider}
                          />
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="baseline"
                        >
                          <Typography className={classes.boldFont} variant="h6">
                            {`${numberReviews}`}
                          </Typography>
                          <Typography variant="body2">&nbsp;</Typography>
                          <Typography variant="body2">reviews</Typography>
                        </Box>
                      </Grid>
                    )}
                    {shouldShowHoursTutored && (
                      <Typography variant="body2">
                        <span style={{ fontWeight: 'bold', color: '#444' }}>
                          {showHoursTutored}
                        </span>{' '}
                        hours tutored
                      </Typography>
                    )}
                    {!shouldShowSubjectList && (
                      <Box className={classes.mobileInformationContainer}>
                        <Typography variant="overline">HOURLY RATE</Typography>
                        <Typography variant="h6">
                          ${hourlyWage}{' '}
                          <span style={{ fontWeight: 300 }}>per hour</span>
                        </Typography>
                      </Box>
                    )}
                  </div>
                </Box>
                <ProfileAvatar
                  src={profilePicture.src}
                  alt="Profile"
                  transform={{ transform: profilePicture.rotation }}
                  firstName={firstName}
                  lastName={lastName}
                  size={AVATAR_STYLE_SM}
                />
              </Box>
              {shouldShowSubjectList && (
                <Box
                  flexGrow={1}
                  display="flex"
                  className={classes.mobileContentContainer}
                >
                  <Box flexDirection="column">
                    <Box className={classes.mobileInformationContainer}>
                      <Typography variant="overline">TEACHES</Typography>
                      <Typography variant="h6">
                        {subjectList.map(subject => `${subject} | `)} {lastElem}
                      </Typography>
                    </Box>
                    <Box className={classes.mobileInformationContainer}>
                      <Typography variant="overline">HOURLY RATE</Typography>
                      <Typography variant="h6">
                        ${hourlyWage}{' '}
                        <span style={{ fontWeight: 300 }}>per hour</span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              <div className={classes.avatarInformation}>
                <Button
                  className={classes.button}
                  component={Link}
                  variant="contained"
                  onClick={handleContactClick}
                >
                  <Typography variant="button">Connect</Typography>
                </Button>
              </div>
            </Link>
          </Box>
        )}
        {!isMobile && (
          <Box display="flex" className={classes.container}>
            <Link
              to={`/u/${profileId}`}
              underline="none"
              openNewTab
              isInternalTutorflyLink
              className={classes.linkText}
            >
              <Box flexDirection="column" className={classes.contentContainer}>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <ProfileAvatar
                    src={profilePicture.src}
                    alt="Profile"
                    transform={{ transform: profilePicture.rotation }}
                    firstName={firstName}
                    lastName={lastName}
                    size={AVATAR_STYLE_SM}
                  />
                </Box>
                <div className={classes.avatarInformation}>
                  {shouldShowReviewScore && (
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      style={{ flexWrap: 'nowrap' }}
                    >
                      <StarIcon className={classes.profileIcon} />
                      <Typography className={classes.boldFont} variant="h6">
                        {`${showReviewScore}`}
                      </Typography>
                      <Box p={1}>
                        <Divider
                          orientation="vertical"
                          className={classes.vDivider}
                        />
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="baseline"
                      >
                        <Typography className={classes.boldFont} variant="h6">
                          {`${numberReviews}`}
                        </Typography>
                        <Typography variant="body2">&nbsp;</Typography>
                        <Typography variant="body2">reviews</Typography>
                      </Box>
                    </Grid>
                  )}
                  {shouldShowHoursTutored && (
                    <Typography variant="body2">
                      <span style={{ fontWeight: 'bold', color: '#444' }}>
                        {showHoursTutored}
                      </span>{' '}
                      hours tutored
                    </Typography>
                  )}
                </div>
                <div className={classes.avatarInformation}>
                  <Button
                    className={classes.button}
                    component={Link}
                    variant="contained"
                    onClick={handleContactClick}
                  >
                    <Typography variant="button">Connect</Typography>
                  </Button>
                </div>
              </Box>
              <Box
                flexGrow={1}
                display="flex"
                className={classes.contentContainer}
              >
                <Box flexDirection="column">
                  <Box>
                    <Typography variant="h4">
                      {school ? `${firstName} - ${school}` : `${firstName}`}
                    </Typography>
                  </Box>
                  {shouldShowSubjectList && (
                    <Box className={classes.informationContainer}>
                      <Typography variant="overline">TEACHES</Typography>
                      <Typography variant="h6">
                        {subjectList.map(subject => `${subject} | `)} {lastElem}
                      </Typography>
                    </Box>
                  )}
                  <Box className={classes.informationContainer}>
                    <Typography variant="overline">HOURLY RATE</Typography>
                    <Typography variant="h6">
                      ${hourlyWage}{' '}
                      <span style={{ fontWeight: 300 }}>per hour</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Link>
            <Box display="flex" flexGrow={1}>
              <Box
                flexDirection="column"
                flexGrow={1}
                className={classes.tabsContainer}
              >
                <TutorCardTabs
                  centered
                  value={tabIndex}
                  onChange={(_e, newIndex) => setTabIndex(newIndex)}
                  aria-label="Search Type"
                >
                  <TutorCardTab label="Intro" />
                  <TutorCardTab label="Availability" />
                </TutorCardTabs>
                {tabIndex === 0 && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    className={bioContainerStyle}
                  >
                    <Box flexGrow={1} style={{ overflow: 'hidden' }}>
                      <Typography variant="body2">{tutorBio}</Typography>
                    </Box>
                    <Box style={{ justifyContent: 'flex-end' }}>
                      <Link
                        to={`/u/${profileId}`}
                        underline="none"
                        openNewTab
                        isInternalTutorflyLink
                        style={{
                          textAlign: 'end',
                          display: 'flex',
                          textDecoration: 'none',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.readMore}
                        >
                          Read more
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    className={bioContainerStyle}
                  >
                    <Box flexGrow={1}>
                      <Typography variant="body2">
                        {tutorAvailability}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        className={classes.button}
                        component={Link}
                        variant="contained"
                        onClick={handleContactClick}
                      >
                        <Typography variant="button">
                          Check availability
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
      <SessionRequestForm
        isOpen={requestSessionOpen}
        userProfile={tutor.profile}
        requesterFirstName={currentUser ? currentUser.profile.firstName : ''}
        tutorId={tutorId}
        onClose={hideModal}
        setDisabled={setDisabled}
        disabledModifier={submitDisabled}
      />
    </div>
  );
}

TutorCardV2.propTypes = {
  client: PropTypes.shape.isRequired,
  tutor: PropTypes.shape.isRequired,
  currentUser: PropTypes.shape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(connect(mapStateToProps), withClient)(TutorCardV2);
