import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import SecondaryButtonV3 from 'shared/components/Buttons/SecondaryButtonV3';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { mapNameToColor, getInitials } from 'shared/utilities/initials';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/AddRounded';
import StripeCheckout from 'react-stripe-checkout';
import PrimaryButtonV2 from 'shared/components/Buttons/PrimaryButtonV2/PrimaryButtonV2';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import withClient from 'shared/components/ApolloClient/withClient';
import ProfileAvatar from 'shared/components/ProfileAvatar/ProfileAvatar';
import SelectZipV2 from 'shared/components/ZipcodeSelectField/SelectZipV2';
import {
  isEmpty,
  isNotEmpty,
  isValidSessionType,
  getZipObject,
  hasProfilePicture,
  sortDaysOfTheWeek,
  stripFileMetadata,
} from 'shared/utilities/utils';
import {
  navigateToInbox,
  navigateFindATutor,
} from 'shared/utilities/navigation';
import { isProfane } from 'shared/utilities/validators';
import {
  SEND_TUTOR_REQUEST_MUTATION,
  ADD_PAYMENT_METHOD_MUTATION,
  GET_USER_CONVERSATIONS,
} from 'shared/constants/gql-constants';
import {
  AVATAR_STYLE_REQUEST,
  STRIPE_PUBLISHABLE_KEY,
  daysOfWeek,
  ProductContext,
} from 'shared/constants/index';
import {
  setTutorRequestPreferences,
  updatePartialUser,
} from 'shared/actions/subscriptions';
import {
  DialogContent,
  DialogActions,
} from 'shared/components/DialogComponents/DialogComponents';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton/PrimaryButton';
import { FormControl } from '@material-ui/core';
import logo from 'shared/assets/TutorflyLogoBB.png';
import withStyles from './SessionRequestFormStyles';
import RequestAttachments from './RequestAttachments';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SessionRequestForm extends React.Component {
  static propTypes = {
    client: PropTypes.shape.isRequired,
    currentUser: PropTypes.shape.isRequired,
    classes: PropTypes.shape.isRequired,
    onClose: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    userProfile: PropTypes.shape.isRequired,
    tutorId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    savedRequestPreferences: PropTypes.shape,
    setTutorRequestPreferences: PropTypes.func.isRequired,
    updatePartialUser: PropTypes.func.isRequired,
    hasPaymentMethod: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onComplete: () => {},
    savedRequestPreferences: null,
  };

  constructor(props) {
    super(props);
    const defaultSessionPreference = 'no-preference';
    const { savedRequestPreferences } = props;
    if (savedRequestPreferences) {
      const {
        subject,
        gradeLevel,
        sessionType,
        zipCode,
        city,
        state,
        daysAvailable,
        timeAvailable,
        firstMessage,
        files,
      } = savedRequestPreferences;

      this.state = {
        subject: isNotEmpty(subject) ? subject : '',
        gradeLevel: isNotEmpty(gradeLevel) ? gradeLevel : '',
        sessionType: isValidSessionType(sessionType)
          ? sessionType
          : defaultSessionPreference,
        zipCode: getZipObject(zipCode, city, state),
        daysAvailable: !daysAvailable ? [] : daysAvailable,
        timeAvailable: isNotEmpty(timeAvailable) ? timeAvailable : '',
        tutoringDetails: isNotEmpty(firstMessage) ? firstMessage : '',
        savedFiles: stripFileMetadata(files),

        savePreferences: true,
        isLoading: false,

        isSubjectValid: true,
        isGradeLevelValid: true,
        isZipCodeValid: true,
        isDaysAvailableValid: true,
        isTimeAvailableValid: true,
        isTutoringDetailsValid: true,

        isPhoneVerifiedStateValid: true,

        navigationState: 'REQUEST_FORM', // { REQUEST_FORM, SUCCESS_FORM }
        cardSavedIndicator: false,
        errorText: '',
      };
    } else {
      this.state = {
        subject: '',
        gradeLevel: '',
        sessionType: 'no-preference',
        zipCode: {},
        daysAvailable: [],
        timeAvailable: '',
        tutoringDetails: '',
        savedFiles: [],

        savePreferences: true,
        isLoading: false,

        isSubjectValid: true,
        isGradeLevelValid: true,
        isZipCodeValid: true,
        isDaysAvailableValid: true,
        isTimeAvailableValid: true,
        isTutoringDetailsValid: true,

        navigationState: 'REQUEST_FORM', // { REQUEST_FORM, SUCCESS_FORM }
        cardSavedIndicator: false,
        errorText: '',
      };
    }
  }

  setSelectedZipcode = zipcode => {
    if (zipcode) {
      this.setState({
        zipCode: zipcode,
      });
    }
  };

  setSavedFiles = files => {
    this.setState({ savedFiles: files });
  };

  navigateMessages = () => {
    navigateToInbox();
  };

  navigateFindATutor = () => {
    navigateFindATutor();
  };

  submitRequest = async () => {
    const {
      subject,
      gradeLevel,
      sessionType,
      zipCode,
      daysAvailable,
      timeAvailable,
      tutoringDetails,
      savePreferences,
      savedFiles,
    } = this.state;
    const { tutorId, client, userProfile } = this.props;
    const {
      firstName,
      lastName,
      profilePicture,
      school,
      gradeLevel: profileGradeLevel,
      gpa,
    } = userProfile;

    const tutorRequestInput = {
      tutorId,
      subject,
      gradeLevel,
      sessionType,
      zipCode: isNotEmpty(zipCode.id) ? zipCode.id : '',
      city: isNotEmpty(zipCode.city) ? zipCode.city : '',
      state: isNotEmpty(zipCode.state) ? zipCode.state : '',
      daysAvailable,
      timeAvailable,
      firstMessage: tutoringDetails,
      files: savedFiles,
    };

    if (this.isFormValid()) {
      this.setState({ isLoading: true });

      try {
        const { data } = await client.mutate({
          mutation: SEND_TUTOR_REQUEST_MUTATION,
          variables: {
            input: tutorRequestInput,
            savePreferences,
          },
          refetchQueries: [
            {
              query: GET_USER_CONVERSATIONS,
            },
          ],
        });

        if (!data.requestTutoringSession) {
          this.setState({ isLoading: false, isPhoneVerifiedStateValid: false });
          return;
        }
        this.setState({ isPhoneVerifiedStateValid: true });

        window.analytics.track('Contact Request Sent', {
          tutorId,
          name: `${firstName} ${lastName}`,
          school,
          hasProfilePicture: hasProfilePicture(profilePicture),
          gradeLevel: profileGradeLevel,
          gpa,
          subject,
          productContext: ProductContext.MARKETPLACE,
        });

        if (savePreferences) {
          this.props.setTutorRequestPreferences({
            subject,
            gradeLevel,
            sessionType,
            zipCode: isNotEmpty(zipCode.id) ? zipCode.id : '',
            city: isNotEmpty(zipCode.city) ? zipCode.city : '',
            state: isNotEmpty(zipCode.state) ? zipCode.state : '',
            daysAvailable,
            timeAvailable,
            firstMessage: tutoringDetails,
          });
        }

        this.setState({ isLoading: false, navigationState: 'SUCCESS_FORM' });
      } catch (error) {
        this.setState({ isLoading: false, errorText: error.toString() });
      }
    }
  };

  handleValueChange = field => event => {
    this.setState({ [field]: event.target.value });
  };

  handleDaySelected = event => {
    this.setState({ daysAvailable: event.target.value });
  };

  handleAvailableDaysChange = field => event => {
    this.setState({ [field]: sortDaysOfTheWeek(event.target.value) });
  };

  handleCheckClick = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  navigateToSuccessForm = () => {
    this.setState({ navigationState: 'SUCCESS_FORM' });
  };

  isFormValid = () => {
    const {
      subject,
      gradeLevel,
      sessionType,
      zipCode,
      daysAvailable,
      timeAvailable,
      tutoringDetails,
    } = this.state;

    const isSubjectValid = !isEmpty(subject) && !isProfane(subject);
    const isGradeLevelValid = !isEmpty(gradeLevel);
    const isZipCodeValid =
      sessionType === 'online' ||
      (!isEmpty(zipCode.id) &&
        !isEmpty(zipCode.city) &&
        !isEmpty(zipCode.state));
    const isDaysAvailableValid = daysAvailable.length !== 0;
    const isTimeAvailableValid =
      !isEmpty(timeAvailable) && !isProfane(timeAvailable);
    const isTutoringDetailsValid = !isProfane(tutoringDetails);

    this.setState({
      isSubjectValid,
      isGradeLevelValid,
      isZipCodeValid,
      isDaysAvailableValid,
      isTimeAvailableValid,
      isTutoringDetailsValid,
    });

    return (
      isSubjectValid &&
      isGradeLevelValid &&
      isZipCodeValid &&
      isDaysAvailableValid &&
      isTimeAvailableValid &&
      isTutoringDetailsValid
    );
  };

  saveToken = async token => {
    const { client } = this.props;
    this.setState({ isLoading: true });

    const input = {
      cardToken: token.id,
      brand: token.card.brand,
      last4: token.card.last4,
      expMonth: token.card.exp_month,
      expYear: token.card.exp_year,
    };

    try {
      const { data } = await client.mutate({
        mutation: ADD_PAYMENT_METHOD_MUTATION,
        variables: {
          input,
        },
      });

      this.props.updatePartialUser({
        profile: {
          creditCardData: data.addPaymentMethod,
        },
      });

      window.analytics.track('Payment Added', {
        numPaymentMethods: 1,
        productContext: ProductContext.MARKETPLACE,
      });

      this.setState({ isLoading: false, cardSavedIndicator: true });
    } catch (err) {
      this.setState({ isLoading: false, errorText: err.toString() });
    }
  };

  renderSuccess = () => {
    const {
      classes,
      onClose,
      userProfile: { firstName, lastName, profilePicture },
      currentUser,
      hasPaymentMethod,
    } = this.props;
    const { cardSavedIndicator, isLoading } = this.state;
    return (
      <div>
        <DialogContent className={classes.dialogContent}>
          <Box>
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <ProfileAvatar
                size={AVATAR_STYLE_REQUEST}
                src={profilePicture.src}
                firstName={firstName}
                lastName={lastName}
              />
              <CheckCircleOutlineRoundedIcon className={classes.successIcon} />
            </Grid>

            <Typography variant="h5" className={classes.successText}>
              {`We sent your request to ${firstName}!`}
            </Typography>
            <Typography variant="body1" className={classes.successText}>
              {`${firstName} will review your tutoring request and respond back shortly. Please communicate with ${firstName} before booking a session from your dashboard.`}
            </Typography>
          </Grid>
          {!hasPaymentMethod &&
            isLoading && (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            )}
          {!hasPaymentMethod &&
            !isLoading && (
              <div>
                <Divider className={classes.hDivider} />
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  wrap="nowrap"
                  className={classes.addPaymentContainer}
                >
                  <PaymentRoundedIcon className={classes.paymentIcon} />
                  <Typography variant="h6" className={classes.prompt}>
                    {`Please add a payment method to book sessions with tutors like ${firstName}!`}
                  </Typography>
                </Grid>
                <StripeCheckout
                  allowRememberMe={false}
                  description="Powered by Stripe"
                  image={logo}
                  name="Tutorfly"
                  panelLabel="Add Card"
                  stripeKey={STRIPE_PUBLISHABLE_KEY}
                  token={this.saveToken}
                  email={currentUser.email.address}
                >
                  <PrimaryButtonV2
                    fullWidth
                    color="primary"
                    variant="contained"
                    className={classes.button}
                  >
                    <AddIcon />
                    Add payment method
                  </PrimaryButtonV2>
                </StripeCheckout>
                <strong className={classes.lineThrough}>
                  OR SKIP FOR NOW AND
                </strong>
              </div>
            )}

          {cardSavedIndicator && (
            <FormHelperText className={classes.stripeConnectSuccessMessage}>
              Your payment method was successfully added!
            </FormHelperText>
          )}

          <List className={classes.secondaryCtaContainer}>
            <Divider className={classes.hDividerNoSpacing} />
            <ListItem
              button
              divider
              onClick={() => {
                navigateToInbox();
              }}
            >
              <ListItemIcon>
                <ChatBubbleOutlineRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={`Send messages to ${firstName}`} />
              <ChevronRightRoundedIcon />
            </ListItem>
            <ListItem
              button
              divider
              onClick={() => {
                navigateFindATutor();
              }}
            >
              <ListItemIcon>
                <SearchRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Find more tutors" />
              <ChevronRightRoundedIcon />
            </ListItem>
          </List>
        </DialogContent>
      </div>
    );
  };

  renderTutorRequestForm = () => {
    const { handleValueChange, setSelectedZipcode, submitRequest } = this;
    const {
      classes,
      onClose,
      userProfile: { firstName, lastName, profilePicture },
      currentUser,
    } = this.props;
    const {
      subject,
      gradeLevel,
      sessionType,
      zipCode,
      daysAvailable,
      timeAvailable,
      tutoringDetails,
      savePreferences,
      isLoading,
      isSubjectValid,
      isGradeLevelValid,
      isZipCodeValid,
      isDaysAvailableValid,
      isTimeAvailableValid,
      isTutoringDetailsValid,
      isPhoneVerifiedStateValid,
    } = this.state;
    const zipCityState =
      !isEmpty(zipCode.city) && !isEmpty(zipCode.state)
        ? `${zipCode.city}, ${zipCode.state}`
        : 'Please enter a ZIP code.';
    const gradeLevelOptions = [
      'Elementary',
      'Middle School',
      'High School',
      'University',
    ];

    return (
      <div>
        <DialogTitle
          id="form-dialog-title"
          disableTypography
          className={classes.dialogTitle}
        >
          <Box
            display="flex"
            flexDirection="row"
            className={classes.dialogTitleHead}
          >
            <Box className={classes.profileContainer}>
              <ProfileAvatar
                size={AVATAR_STYLE_REQUEST}
                src={profilePicture.src}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>
            <Box flexGrow={1} alignItems="center" display="flex">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Typography variant="h5">
                  {`Tutor Request to ${firstName}`}
                </Typography>
                <Typography variant="body1">
                  {`Please answer the questions below to provide useful information to ${firstName}.`}
                </Typography>
              </Grid>
            </Box>
            <Box>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column">
            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                Which subject or class do you want help with?
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  type="text"
                  variant="outlined"
                  placeholder="Please write all subjects that apply."
                  fullWidth
                  value={subject}
                  error={!isSubjectValid}
                  helperText={
                    !isSubjectValid
                      ? 'Please enter a subject that abides by our user guidelines.'
                      : ''
                  }
                  onChange={handleValueChange('subject')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                {
                  'Do you have a strong preference between in-person and online tutoring sessions?'
                }
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  id="select-tutor-preference"
                  onChange={handleValueChange('sessionType')}
                  value={sessionType}
                  select
                  variant="outlined"
                  fullWidth
                  error={!isGradeLevelValid}
                >
                  <MenuItem value="no-preference">No Preference</MenuItem>
                  <MenuItem value="in-person">In-Person</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            <Collapse in={sessionType !== 'online'}>
              <Grid item xs={12} className={classes.field}>
                <Typography variant="subtitle1" className={classes.prompt}>
                  {'Where are you located?'}
                </Typography>
                <div className={classes.selectField}>
                  <SelectZipV2
                    setSelectedZipCode={setSelectedZipcode}
                    zipCode={zipCode}
                    showLabel={false}
                    error={!isZipCodeValid}
                  />
                  <FormHelperText className={classes.formHelperText}>
                    {zipCityState}
                  </FormHelperText>
                </div>
              </Grid>
            </Collapse>

            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                {"What is your or your student's grade level?"}
              </Typography>
              <FormControl
                className={classes.formControl}
                error={!isGradeLevelValid}
                fullWidth
              >
                <TextField
                  id="select-grade-level"
                  value={gradeLevel}
                  onChange={handleValueChange('gradeLevel')}
                  select
                  variant="outlined"
                  fullWidth
                  error={!isGradeLevelValid}
                >
                  {gradeLevelOptions.map(option => (
                    <MenuItem value={option}>{option}</MenuItem>
                  ))}
                </TextField>
                {!isGradeLevelValid && (
                  <FormHelperText>Please select a grade level.</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                Which days of the week work best for you to have tutoring
                sessions?
              </Typography>
              <FormControl className={classes.formControl} fullWidth>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  size="small"
                  variant="outlined"
                  value={daysAvailable}
                  onChange={this.handleDaySelected}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          color="primary"
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {daysOfWeek.map(day => (
                    <MenuItem key={day} value={day}>
                      <Checkbox checked={daysAvailable.indexOf(day) > -1} />
                      <ListItemText primary={day} />
                    </MenuItem>
                  ))}
                </Select>
                {!isDaysAvailableValid && (
                  <FormHelperText>
                    Please select the days that work best for you.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                {
                  'Which times work best for you to have tutoring sessions on those days?'
                }
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                type="text"
                rows="2"
                fullWidth
                multiline
                value={timeAvailable}
                error={!isTimeAvailableValid}
                helperText={
                  !isTimeAvailableValid
                    ? 'Please enter a description that describes your general availability on those days and abides by our user guidelines.'
                    : ''
                }
                placeholder="I'm generally free after 2 pm..."
                onChange={handleValueChange('timeAvailable')}
              />
            </Grid>

            <Grid item xs={12} className={classes.field}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography
                    variant="subtitle1"
                    className={classes.detailsPrompt}
                    gutterBottom
                  >
                    {`Type out an initial message to send to the tutor.`}
                  </Typography>
                  <Typography variant="body2">
                    {`The more detail you provide, the easier and faster your tutor can start preparing for sessions!`}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip
                    title={
                      <span
                        style={{ fontSize: '15px' }}
                      >{`This will be your first message in your chat with ${firstName}`}</span>
                    }
                  >
                    <InfoOutlinedIcon
                      aria-label="Info"
                      title="Hello"
                      color="primary"
                    />
                  </Tooltip>
                </Box>
              </Box>
              <div style={{ width: '100%' }}>
                <Box
                  display="flex"
                  bgcolor="background.paper"
                  pt={2.5}
                  pb={0.5}
                  alignItems="flex-end"
                >
                  <Box flexGrow={1}>
                    <Paper elevation={0} className={classes.paper}>
                      <Typography variant="body1">
                        {`Hi ${
                          currentUser ? currentUser.profile.firstName : 'there'
                        }, I am looking forward to connecting with you and seeing how I may help you out!`}
                      </Typography>
                    </Paper>
                  </Box>
                  <Box pl={2}>
                    <Avatar
                      src={profilePicture.src}
                      className={classes.avatar}
                      alt={`${firstName} ${lastName}`}
                      style={{
                        background: mapNameToColor(firstName, lastName),
                      }}
                    >
                      {getInitials(firstName, lastName)}
                    </Avatar>
                  </Box>
                </Box>
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                rows="4"
                type="text"
                fullWidth
                multiline
                value={tutoringDetails}
                error={!isTutoringDetailsValid}
                helperText={
                  !isTutoringDetailsValid
                    ? 'Please enter a message that abides by our user guidelines.'
                    : ''
                }
                placeholder={`Hi ${firstName}, I would like to book a session with you.`}
                onChange={handleValueChange('tutoringDetails')}
              />
            </Grid>

            <Grid item xs={12} className={classes.field}>
              <Typography variant="subtitle1" className={classes.prompt}>
                (Optional) Upload files to include with your request:
              </Typography>
              <RequestAttachments
                setSavedFiles={this.setSavedFiles}
                savedFiles={this.state.savedFiles}
              />
            </Grid>
          </Grid>

          {!isPhoneVerifiedStateValid && (
            <Box display="flex">
              <Box mr={1}>
                <ErrorRoundedIcon color="secondary" />
              </Box>
              <Box flexGrow={1}>
                <Typography
                  variant="subtitle1"
                  className={classes.prompt}
                  gutterBottom
                >
                  Please verify your phone number via your Tutorfly Account
                  Settings prior to sending requests to Tutorfly tutors.
                </Typography>
                <SecondaryButtonV3
                  size="small"
                  href="/account-settings/contact"
                  target="_blank"
                >
                  Verify Phone
                </SecondaryButtonV3>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <Grid container>
              <Grid item sm={6} xs={12}>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      classes={{ checked: classes.checkbox }}
                      onChange={this.handleCheckClick('savePreferences')}
                      checked={savePreferences}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      className={classes.savePreferencesText}
                    >
                      {'Save these preferences for future tutoring requests?'}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PrimaryButton
                  className={classes.submitButton}
                  onClick={submitRequest}
                >
                  Connect
                </PrimaryButton>
              </Grid>
              {this.state.errorText !== '' && (
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    color="error"
                    className={classes.errorText}
                  >
                    {this.state.errorText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </DialogActions>
      </div>
    );
  };

  render() {
    const { onClose, fullScreen, isOpen } = this.props;
    const { navigationState } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        open={isOpen}
        fullScreen={fullScreen}
        onClose={onClose}
        scroll="body"
        fullWidth
        maxWidth="sm"
      >
        {navigationState === 'REQUEST_FORM' && this.renderTutorRequestForm()}
        {navigationState === 'SUCCESS_FORM' && this.renderSuccess()}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const { currentUser } = state.subscriptions;
  let savedRequestPreferences;
  let hasPaymentMethod;
  if (currentUser) {
    if (currentUser.profile) {
      if (currentUser.profile.tutorRequestPreferences) {
        savedRequestPreferences = currentUser.profile.tutorRequestPreferences;
      }
    }
    hasPaymentMethod = currentUser.profile.creditCardData.length > 0;
  }
  return {
    savedRequestPreferences,
    currentUser,
    hasPaymentMethod,
  };
};

export default compose(
  withMobileDialog(),
  withStyles,
  withClient,
  connect(mapStateToProps, { setTutorRequestPreferences, updatePartialUser }),
)(SessionRequestForm);
