/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import deburr from 'lodash/deburr';
import { compose } from 'redux';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import withClient from 'shared/components/ApolloClient/withClient';
import { ZIP_CODE_SOFT_SEARCH_V2 } from 'shared/constants/gql-constants';

const styles = theme => ({
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
    fontSize: 13,
  },
  container: {
    flexGrow: 1,
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  button: {
    marginTop: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    height: 'auto',
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, error, ...other } = inputProps;

  return (
    <TextField
      error={error}
      fullWidth
      autocomplete="off"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function noRenderZipCodeFound(classes, message) {
  return (
    <div className={classes.root}>
      <Typography
        align="center"
        color="textSecondary"
        className={classes.noOptionsMessage}
      >
        {message}
      </Typography>
    </div>
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, suggestions) {
  if (!value) {
    return [];
  }

  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep = suggestion.label.toLowerCase().includes(inputValue);
        return keep;
      });
}

class SelectZipV2 extends React.Component {
  state = {
    inputValue: null,
    suggestions: [],
  };

  handleInputChange = event => {
    const { client } = this.props;
    const { value } = event.target;
    this.setState({ inputValue: value });

    if (value && value.length > 0) {
      client
        .query({
          fetchPolicy: 'network-only',
          query: ZIP_CODE_SOFT_SEARCH_V2,
          variables: {
            zipInput: value,
            limit: 5,
          },
        })
        .then(({ data }) => {
          const suggestions = data.softSearchZipCodeV2.map(suggestion => ({
            value: suggestion,
            label: `${suggestion.id} ${suggestion.city}, ${suggestion.state}`,
          }));

          if (suggestions.length === 1) {
            this.props.setSelectedZipCode(suggestions[0].value);
            this.setState({
              inputValue: suggestions[0].value.id,
              suggestions: [],
            });
          } else {
            this.setState({
              suggestions,
            });
          }
        })
        .catch(() => {});
    }
  };

  // Item is selected
  handleChange = item => {
    this.props.setSelectedZipCode(item.value);

    this.setState({
      inputValue: item.value.id,
      suggestions: [],
    });
  };

  render() {
    const { classes, zipCode, showLabel, error } = this.props;
    const { inputValue, suggestions } = this.state;
    const filteredSuggestions = getSuggestions(inputValue, suggestions);
    const hasSuggestions = filteredSuggestions.length > 0;
    const noZipCodeMessage = 'No ZIP Code Found';

    return (
      <Downshift
        id="downshift-simple"
        inputValue={inputValue !== null ? inputValue : zipCode.id}
        onChange={this.handleChange}
        selectedItem={zipCode.id}
        isOpen={hasSuggestions}
      >
        {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                onChange: this.handleInputChange,
                placeholder: 'Set a ZIP code',
                shrink: 'true',
              }),
              label: showLabel ? 'Set a ZIP code' : null,
              error,
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {hasSuggestions
                  ? filteredSuggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem: zipCode.id,
                      }),
                    )
                  : noRenderZipCodeFound(classes, noZipCodeMessage)}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

SelectZipV2.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  setSelectedZipCode: PropTypes.func.isRequired,
  zipCode: PropTypes.object.isRequired,
  showLabel: PropTypes.bool,
  error: PropTypes.bool,
};

SelectZipV2.defaultProps = {
  showLabel: true,
  error: false,
};

export default compose(withStyles(styles), withClient)(SelectZipV2);
