import React from 'react';
import PropTypes from 'prop-types';

const PlaceholderComponent = ({ typename, ...props }) => {
  return (
    <div style={{ border: '1px solid red', padding: '10px', margin: '10px 0' }}>
      <h3>Unknown Component: {typename}</h3>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </div>
  );
};

PlaceholderComponent.propTypes = {
  typename: PropTypes.string.isRequired,
};

export default PlaceholderComponent;
