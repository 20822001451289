// ChunkComponent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import PrimaryInfo from '../marketplace/components/Authentication/ApplyToTutorForm/components/PrimaryInfo';
import Contact from '../shared/page/contact/Contact';
import SearchV2 from '../shared/page/search/SearchV2';
import Pricing from '../marketplace/routes/pricing/Pricing';
import ContactHero from '../marketplace/components/ContactHero/ContactHero';
import ContactHeroNode from '../marketplace/components/ContactHeroNode/ContactHeroNode';
import Tutor from '../shared/assets/images/tutor-3.png';

const ChunkComponent = ({ chunkname }) => {
  const chunkMapping = {
    contact: Contact,
    'find-a-tutor': SearchV2,
    pricing: Pricing,
    support: ContactHero,
    apply: () => (
      <ContactHeroNode
        title="Apply now"
        subtitle="Complete the form to start your application and we'll be in touch with the next steps."
        image={Tutor}
      >
        <PrimaryInfo />
      </ContactHeroNode>
    ),
  };

  const Component = chunkMapping[chunkname];

  if (!Component) {
    console.error(`No component found for chunk: ${chunkname}`);
    return null;
  }

  return <Component />;
};

ChunkComponent.propTypes = {
  chunkname: PropTypes.string.isRequired,
};

export default ChunkComponent;
