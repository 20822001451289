import React from 'react';
import PropTypes from 'prop-types';

const getImageUrl = imageArray => {
  return imageArray && imageArray.length > 0 && imageArray[0].url
    ? imageArray[0].url
    : '';
};

const MediaitemComponent = ({ image, title, textContent }) => {
  return (
    <div className="tw-m-0 tw-p-0 tw-max-w-[1440px] tw-mx-auto">
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-12 tw-px-3 xl:tw-px-0">
        <div className="sm:tw-col-span-4 tw-flex tw-justify-center tw-items-center tw-max-h-[250px] tw-max-w-[300px] tw-p-8 tw-shadow-md xs:tw-mx-auto xs:tw-my-8 xs:tw-text-center">
          {image &&
            image.length > 0 && (
              <img
                src={getImageUrl(image)}
                alt=""
                className="tw-max-w-full tw-h-auto tw-max-h-full"
              />
            )}
        </div>
        <div className="sm:tw-col-span-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center sm:tw-text-left sm:tw-items-start sm:tw-pl-4">
          <h6 className="tw-text-xl tw-font-semibold tw-mb-4 tw-text-black">
            {title}
          </h6>
          <p className="tw-text-base tw-font-raleway tw-whitespace-pre-line tw-text-black">
            {textContent}
          </p>
        </div>
      </div>
      <hr className="tw-my-8 tw-mx-8 tw-border-t tw-border-gray-100" />
    </div>
  );
};

MediaitemComponent.propTypes = {
  image: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string,
  textContent: PropTypes.string,
};

MediaitemComponent.defaultProps = {
  image: [],
  title: '',
  textContent: '',
};

export default MediaitemComponent;
