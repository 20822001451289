/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const squidexClient = axios.create({
  baseURL: process.env.SQUIDEX_API_URL,
  headers: {
    'X-Flatten': 'true',
    Authorization: `Bearer ${process.env.SQUIDEX_CLIENT_ID}:${
      process.env.SQUIDEX_CLIENT_SECRET
    }`,
  },
});

export const fetchAllPages = async () => {
  try {
    const query = `
      query Pages {
        queryPageContents {
          id
          flatData {
            slug
            title
            metaDescription
            parentPage {
              id
            }
            menuOrder
          }
        }
      }
    `;

    const response = await squidexClient.post('', { query });
    return response.data.data.queryPageContents;
  } catch (error) {
    console.error('Error fetching pages:', error);
    return [];
  }
};

export const fetchPageContent = async slug => {
  try {
    const query = `
      query Page($filter: String) {
        queryPageContents(filter: $filter) {
          id
          flatData {
            slug
            title
            parentPage {
              id
            }
            metaDescription
            blocks {
              ... on ButtonComponent {
                __typename
                text
                link
                color
              }
              ... on ChunkComponent {
                __typename
                chunkname
              }
              ... on BlogheroComponent {
                __typename
              }
              ... on RichtextComponent {
                __typename
                content
              }
              ... on LandingheroComponent {
                __typename
                headline
                subtitle
                image {
                  id
                  url
                  fileName
                  fileSize
                  mimeType
                  thumbnailUrl
                }
              }
              ... on MediaitemComponent {
                __typename
                image {
                  url
                }
                title
                textContent
              }
              ... on SubpageheaderComponent {
                __typename
                  title
                  subtitle
                  subpageColor
                  showRoundedShape
              }
              ... on TextandimageComponent {
                __typename
                title
                text
                themecolor
                image {
                  id
                  url
                  fileName
                  fileSize
                  mimeType
                  thumbnailUrl
              }

              }
              ... on TestimonialheroComponent {
                __typename
                image {
                  id
                  url
                  fileName
                  fileSize
                  mimeType
                  thumbnailUrl
                }
                quote
                name
                location
              }
              ... on ColumnsComponent {
                __typename
                columns
                blocks {
                  ... on RichtextComponent {
                    __typename
                    content
                  }
                  ... on ButtonComponent {
                    __typename
                    text
                    link
                    color
                  }
                  ... on CardComponent {
                    __typename
                    title
                    subtitle
                    text
                    button {
                      ... on ButtonComponent {
                        __typename
                        text
                        link
                        color
                      }
                    }
                    image {
                      id
                      url
                      fileName
                      fileSize
                      mimeType
                      thumbnailUrl
                    }
                  }
                  ... on FeatureiconComponent {
                    __typename
                    icon {
                      id
                      url
                      fileName
                      fileSize
                      mimeType
                      thumbnailUrl
                    }
                    title
                    description
                    link
                    linkText
                  }
                }
              }
              ... on BlueheroComponent {
                __typename
                title
                headline
                text
                image {
                  id
                  url
                  fileName
                  fileSize
                  mimeType
                  thumbnailUrl
                }
                button {
                  ... on ButtonComponent {
                    __typename
                    text
                    link
                    color
                  }
                }
              }
              ... on MeettheteamComponent {
                __typename
                sectionTitle
                teamMembers {
                  name
                  title
                  description
                  image {
                    url
                  }
                  hoverImage {
                    url
                  }
                }
              }
              ... on PartnersComponent {
                __typename
                sectionTitle
                verifiedByTitle
                verifiedByDescription
                verifiedByLogos {
                  verifiedByLogo {
                    id
                    url
                    fileName
                    fileSize
                    mimeType
                    thumbnailUrl
                  }
                }
                partnersTitle
                partnersDescription
                partnerLogos {
                  partnerLogo {
                    id
                    url
                    fileName
                    fileSize
                    mimeType
                    thumbnailUrl
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      filter: `data/slug/iv eq '${slug}'`,
    };

    const response = await squidexClient.post('', { query, variables });
    console.error('API Response:', response.data);
    const pages = response.data.data.queryPageContents;
    return pages.length > 0 ? pages[0] : null;
  } catch (error) {
    console.error(`Error fetching page content for slug ${slug}:`, error);
    return null;
  }
};

export const fetchHomePage = async () => {
  return fetchPageContent('home');
};

export const fetchHomePageServerSide = async () => {
  try {
    const homePageData = await fetchHomePage();

    return {
      chunks: ['home'],
      title:
        homePageData && homePageData.flatData && homePageData.flatData.title
          ? homePageData.flatData.title
          : 'Tutorfly - Powered by Kip McGrath',
      description:
        homePageData &&
        homePageData.flatData &&
        homePageData.flatData.metaDescription
          ? homePageData.flatData.metaDescription
          : 'Affordable K-12 and university peer tutoring. Anytime. Anywhere.',
      homePageData,
    };
  } catch (error) {
    console.error('Error fetching home page server-side:', error);
    return {
      chunks: ['home'],
      title: 'Tutorfly - Powered by Kip McGrath',
      description:
        'Affordable K-12 and university peer tutoring. Anytime. Anywhere.',
      homePageData: null,
    };
  }
};

export const fetchPageById = async id => {
  try {
    const query = `
      query FindPagesContent($id: String!) {
        findPagesContent(id: $id) {
          id
          flatData {
            slug
            title
            metaDescription
          }
        }
      }
    `;

    const variables = { id };

    const response = await squidexClient.post('', { query, variables });
    return response.data.data.findPagesContent;
  } catch (error) {
    console.error(`Error fetching page content for id ${id}:`, error);
    return null;
  }
};

export const fetchTopLevelPages = async () => {
  try {
    const query = `
      query TopLevelPages {
        queryPageContents(
          filter: "data/mainMenu/iv eq true",
          orderby: "data/menuOrder/iv asc"
        ) {
          id
          flatData {
            slug
            title
            menuTitle
            metaDescription
            menuOrder
          }
        }
      }
    `;

    const response = await squidexClient.post('', { query });
    return response.data.data.queryPageContents;
  } catch (error) {
    console.error('Error fetching top-level pages:', error);
    return [];
  }
};

export const fetchPageBySlug = async slug => {
  try {
    const query = `
      query PageBySlug($slug: String!) {
        queryPageContents(filter: "data/slug/iv eq $slug") {
          id
          flatData {
            slug
            title
            parentPage {
              id
            }
          }
        }
      }
    `;

    const variables = { slug };

    const response = await squidexClient.post('', { query, variables });
    const pages = response.data.data.queryPageContents;
    return pages.length > 0 ? pages[0] : null;
  } catch (error) {
    console.error(`Error fetching page by slug ${slug}:`, error);
    return null;
  }
};

export const fetchSubPages = async parentId => {
  try {
    const query = `
      query SubPages($parentId: String!) {
        queryPageContents(
          filter: "data/parentPage/iv/id eq $parentId",
          orderby: "data/menuOrder/iv asc"
        ) {
          id
          flatData {
            slug
            title
            menuOrder
          }
        }
      }
    `;

    const variables = { parentId };

    const response = await squidexClient.post('', { query, variables });
    return response.data.data.queryPageContents;
  } catch (error) {
    console.error(`Error fetching subpages for parent ID ${parentId}:`, error);
    return [];
  }
};

export default squidexClient;
