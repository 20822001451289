import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchTabs from 'shared/components/tabs/SearchTabs';
import SearchTab from 'shared/components/tab/SearchTab';
import Student from 'shared/assets/images/student-04.png';
import StudentContact from 'shared/assets/images/student-03.png';
import ContactHero from '../../../marketplace/components/ContactHero/ContactHero';
import HeaderWhite from '../../../marketplace/components/HeaderWhite/HeaderWhite';
import SalesforceForm from '../../../marketplace/components/SalesforceForm/SalesforceForm';

const useStyles = makeStyles(theme => ({
  tabContainer: {
    top: '15em',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '13.5em',
    },
  },
}));

function Contact() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <React.Fragment>
      <div style={{ backgroundColor: '#00346c', position: 'relative' }}>
        <HeaderWhite
          breadcrumb="Contact Us"
          title="Contact Us"
          subtitle="We'd love to hear from you!"
        />
        <div className={classes.tabContainer}>
          <div className={classes.tabSection}>
            <SearchTabs
              value={tabIndex}
              onChange={handleChangeTab}
              aria-label="Contact Form Type"
              variant="standard"
            >
              <SearchTab label="For Schools" />
              <SearchTab label="For Families" />
            </SearchTabs>
          </div>
        </div>
      </div>
      {tabIndex === 0 && (
        <SalesforceForm
          subtitle="For Schools Inquiry"
          title="Request more information about the Tutorfly for Schools program"
          image={StudentContact}
          showWave={false}
        />
      )}
      {tabIndex === 1 && (
        <ContactHero
          subtitle="For Families Inquiry"
          title="Get support or information for the Tutorfly Marketplace"
          image={Student}
          description=""
        />
      )}
    </React.Fragment>
  );
}

export default Contact;
