exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@tailwind base;@tailwind components;@tailwind utilities;:root{--max-content-width:1000px;--max-contact-form-width:500px;--max-contact-tutor-search-width:1400px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--screen-xl-min:1440px}html{color:#222;font-weight:400;font-size:1em;font-family:Raleway,Segoe UI,HelveticaNeue-Light,sans-serif;font-family:var(--font-family-base);line-height:1.375}body{margin:0}a{color:#0074c2}::-moz-selection{background:#b3d4fc;text-shadow:none}::selection{background:#b3d4fc;text-shadow:none}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}audio,canvas,iframe,img,svg,video{vertical-align:middle}fieldset{border:0;margin:0;padding:0}textarea{resize:vertical}.browserupgrade{margin:.2em 0;background:#ccc;color:#000;padding:.2em 0}@media print{*,:after,:before{background:transparent!important;color:#000!important;-webkit-box-shadow:none!important;box-shadow:none!important;text-shadow:none!important}a,a:visited{text-decoration:underline}a[href]:after{content:\" (\" attr(href) \")\"}abbr[title]:after{content:\" (\" attr(title) \")\"}a[href^=\"#\"]:after,a[href^=\"javascript:\"]:after{content:\"\"}blockquote,pre{border:1px solid #999;page-break-inside:avoid}thead{display:table-header-group}img,tr{page-break-inside:avoid}img{max-width:100%!important}h2,h3,p{orphans:3;widows:3}h2,h3{page-break-after:avoid}}:root{--font-family-base:\"Raleway\",\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;color:#555}.r9HvQ{@apply tw-grid tw-grid-cols-1 tw-gap-8}._1v7uh{text-align:center;@apply tw-grid tw-grid-cols-1 tw-gap-8}._3lWhf{@apply md:tw-grid-cols-2}._3VHxg{@apply md:tw-grid-cols-3}._1SSye{@apply md:tw-grid-cols-4}@layer base{html{font-family:Raleway,Segoe UI,HelveticaNeue-Light,sans-serif;font-family:var(--font-family-base)}}", ""]);

// exports
exports.locals = {
	"columns-grid": "r9HvQ",
	"columns-grid-1": "_1v7uh",
	"columns-grid-2": "_3lWhf",
	"columns-grid-3": "_3VHxg",
	"columns-grid-4": "_1SSye"
};