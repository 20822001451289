import React from 'react';
import PropTypes from 'prop-types';
import butterfly from 'shared/assets/svg/butterfly-blue.svg';

const WaveSvg = () => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1920 264"
    className="tw-w-full tw-h-full"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#ffffff"
      d="M0,80.2c0,0,321.6-128.1,834.6-60.6S1375.2,240.4,1920,265H0V80.2z"
    />
  </svg>
);

const LandingHero = ({ headline, subtitle, image }) => {
  const getImageUrl = imageArray => {
    if (!Array.isArray(imageArray) || imageArray.length === 0) return '';
    const firstImage = imageArray[0];
    return firstImage.url || firstImage.thumbnailUrl || '';
  };

  return (
    <div className="tw-relative tw-overflow-hidden tw-z-[-1] tw-mb-[-100px] tw-bg-[#4CA7E0] tw-w-full">
      <div
        className="tw-absolute tw-inset-0 tw-bg-no-repeat tw-bg-cover"
        style={{
          backgroundImage: `url(${butterfly})`,
          backgroundSize: '100em',
          backgroundPosition: '50% -25em',
        }}
      />
      <div className="tw-max-w-[1440px] tw-mx-auto tw-px-[5%] tw-relative tw-z-10">
        <div className="tw-grid lg:tw-grid-cols-2 tw-gap-6 tw-items-start">
          <div className="tw-flex tw-flex-col tw-justify-center tw-h-full lg:tw-h-[500px] md:tw-text-center lg:tw-text-left">
            <h1 className="tw-text-4xl xs:tw-text-5xl lg:tw-text-5xl xl:tw-text-6xl tw-font-bold tw-text-white tw-mb-4">
              {headline}
            </h1>
            {subtitle && (
              <h2 className="tw-text-3xl tw-text-gray">{subtitle}</h2>
            )}
          </div>
          <div className="tw-flex tw-justify-center lg:tw-justify-end">
            {image &&
              image.length > 0 && (
                <img
                  src={getImageUrl(image)}
                  alt={headline}
                  className="tw-w-auto tw-h-[480px] lg:tw-h-[740px] tw-mt-0 lg:tw-mt-13 tw-mb-[-1em] lg:tw-mb-0"
                  width={image[0].width}
                  height={image[0].height}
                />
              )}
          </div>
        </div>
        <hr className="tw-border-t tw-border-white tw-mt-8" />
      </div>
      <div className="tw-z-20 tw-absolute tw-left-1/2 tw-bottom-0 tw-w-[1920px] tw-h-[115px] md:tw-h-[265px] tw-flex tw-justify-center tw-min-w-[100vw] tw-transform tw--translate-x-1/2">
        <WaveSvg />
      </div>
    </div>
  );
};

LandingHero.propTypes = {
  headline: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileSize: PropTypes.number,
      mimeType: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ),
};

LandingHero.defaultProps = {
  subtitle: '',
  image: [],
};

export default LandingHero;
