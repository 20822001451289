/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';
import { PropTypes } from 'prop-types';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PrimaryButtonV3 from 'shared/components/Buttons/PrimaryButtonV3';
import { errorAlert, successAlert } from 'shared/utilities/alerts';
import { isNotEmpty } from 'shared/utilities/utils';
import { SHOW_REQUEST_A_TUTOR_FORM } from 'shared/constants/index';
import withStyles from './ContactFormStyles';

const GENERAL_INQUIRY_QUERY = gql`
  query generalInquiry($input: sendGeneralInquiryInput!) {
    sendGeneralInquiryQuery(input: $input)
  }
`;
const TECHNICAL_ISSUE_QUERY = gql`
  query technicalIssue($input: sendTechnicalIssueInput!) {
    sendTechnicalIssueQuery(input: $input)
  }
`;

class ContactForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line
    currentUser: PropTypes.object.isRequired,
    // isDispute: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      inquiryType: 'General Inquiry',
      // inquiryType: 'Technical Is',
      contactName: '',
      contactEmail: '',
      contactMessage: '',

      // if it's a technical inquiry,
      deviceType: 'Desktop/Laptop', // or Mobile

      // if it's a general query,
      contactSubject: '',

      loading: false,

      subjectFieldValid: true,
      nameFieldValid: true,
      emailFieldValid: true,
      messageFieldValid: true,
    };
  }

  handleValueChange = field => e => {
    if (e.target.value === 'Request a Tutor') {
      window.location.href = '/request-a-tutor';
    }

    this.setState({ [field]: e.target.value });
  };

  checkFieldsValid = () => {
    const {
      inquiryType,
      contactName,
      contactEmail,
      contactMessage,
      contactSubject,
    } = this.state;
    let {
      subjectFieldValid,
      nameFieldValid,
      emailFieldValid,
      messageFieldValid,
    } = this.state;

    subjectFieldValid = isNotEmpty(contactSubject);
    nameFieldValid = isNotEmpty(contactName);
    emailFieldValid = isNotEmpty(contactEmail);
    messageFieldValid = isNotEmpty(contactMessage);

    let allFieldsValid = false;

    if (
      inquiryType === 'General Inquiry' &&
      subjectFieldValid &&
      messageFieldValid &&
      nameFieldValid &&
      emailFieldValid
    ) {
      allFieldsValid = true;
    } else if (
      inquiryType === 'Technical Issue' &&
      messageFieldValid &&
      nameFieldValid &&
      emailFieldValid
    ) {
      allFieldsValid = true;
    }

    this.setState({
      subjectFieldValid,
      nameFieldValid,
      emailFieldValid,
      messageFieldValid,
    });

    return allFieldsValid;
  };

  handleRequest = client => async () => {
    if (!this.checkFieldsValid()) {
      return;
    }

    this.setState({ loading: true });
    const {
      inquiryType,
      deviceType,
      contactName,
      contactEmail,
      contactMessage,
      contactSubject,
    } = this.state;

    try {
      if (inquiryType === 'General Inquiry') {
        try {
          await client.query({
            query: GENERAL_INQUIRY_QUERY,
            variables: {
              input: {
                subject: contactSubject,
                name: contactName,
                email: contactEmail,
                message: contactMessage,
              },
            },
          });
          this.setState({ loading: false });
        } catch (err) {
          this.setState({ loading: false });
          console.error(err.toString());
        }
      } else if (inquiryType === 'Technical Issue') {
        try {
          await client.query({
            query: TECHNICAL_ISSUE_QUERY,
            variables: {
              input: {
                name: contactName,
                email: contactEmail,
                deviceBeingUsed: deviceType,
                issueDescription: contactMessage,
                // We supported inputting screen shot images at one point.
              },
            },
          });
          this.setState({ loading: false });
        } catch (err) {
          this.setState({ loading: false });
          console.error(err.toString());
        }
      } else {
        errorAlert(
          'Unable to send message.',
          'Something in your browser has gone wrong.',
        );
        this.setState({ loading: false });
        return;
      }

      successAlert(
        'Message Sent',
        'Thank you! Your message has been successfully submitted.',
      );
    } catch (err) {
      errorAlert('Unable to send message', err.toString());
    }

    this.setState({
      contactName: '',
      contactEmail: '',
      contactMessage: '',
      contactSubject: '',
      loading: false,
    });
  };

  render() {
    const { classes, currentUser } = this.props;

    const {
      inquiryType,
      deviceType,
      contactName,
      contactEmail,
      contactMessage,
      contactSubject,
      loading,
      subjectFieldValid,
      nameFieldValid,
      emailFieldValid,
      messageFieldValid,
    } = this.state;

    const { handleRequest, handleValueChange } = this;

    return (
      <ApolloConsumer>
        {client => (
          <div>
            <FormControl fullWidth>
              <Select
                id="inquiryType"
                fullWidth
                onChange={handleValueChange('inquiryType')}
                value={inquiryType}
                variant="outlined"
                className={classes.input}
              >
                {(SHOW_REQUEST_A_TUTOR_FORM || !currentUser) && (
                  <MenuItem value="Request a Tutor">Request a Tutor</MenuItem>
                )}
                <MenuItem value="Technical Issue">Technical Issue</MenuItem>
                <MenuItem value="General Inquiry">General Inquiry</MenuItem>
              </Select>
            </FormControl>

            {inquiryType === 'General Inquiry' && (
              <TextField
                className={`${classes.input} ${classes.formControl}`}
                fullWidth
                onChange={handleValueChange('contactSubject')}
                placeholder="Subject"
                required
                value={contactSubject}
                error={!subjectFieldValid}
                variant="outlined"
              />
            )}

            <TextField
              className={`${classes.input} ${classes.formControl}`}
              fullWidth
              onChange={handleValueChange('contactName')}
              placeholder="Your Name"
              required
              value={contactName}
              error={!nameFieldValid}
              variant="outlined"
            />

            <TextField
              className={`${classes.input} ${classes.formControl}`}
              fullWidth
              onChange={handleValueChange('contactEmail')}
              placeholder="Your Email"
              required
              value={contactEmail}
              error={!emailFieldValid}
              variant="outlined"
            />

            {inquiryType === 'Technical Issue' && (
              <React.Fragment>
                <div className={classes.deviceControl}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="deviceType" className={classes.label}>
                      Device Being Used
                    </InputLabel>
                    <Select
                      fullWidth
                      onChange={handleValueChange('deviceType')}
                      value={deviceType}
                      className={`${classes.input}`}
                      style={{ padding: '11px 14px' }}
                    >
                      <MenuItem value="Desktop/Laptop">Desktop/Laptop</MenuItem>
                      <MenuItem value="Mobile Device">Mobile Device</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </React.Fragment>
            )}

            <TextField
              id="message"
              placeholder="Your Message"
              type="text"
              multiline
              rows="5"
              className={this.props.classes.textField}
              InputProps={{
                classes: {
                  input: this.props.classes.resize,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={handleValueChange('contactMessage')}
              value={contactMessage}
              error={!messageFieldValid}
            />

            <div className={classes.formControl}>
              {loading ? (
                <CircularProgress />
              ) : (
                <PrimaryButtonV3
                  onClick={handleRequest(client)}
                  className={this.props.classes.submitButton}
                >
                  Submit Inquiry
                </PrimaryButtonV3>
              )}
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.subscriptions.currentUser,
});

export default compose(connect(mapStateToProps), withStyles)(ContactForm);
