// components/Button.js
import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    fontWeight: '400',
    flexWrap: 'nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: theme.spacing(6),
    color: 'white',
    textTransform: 'uppercase',
    margin: 'auto',
    borderRadius: '1.5rem',
    '&:focus': {
      outline: 0,
      borderWidth: '2px',
    },
    borderWidth: '2px',
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  orange: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const Button = ({ text, link, color }) => {
  const classes = useStyles();

  return (
    <MuiButton
      href={link}
      className={`${classes.root} ${classes[color]}`}
      disableElevation
      variant="contained"
    >
      {text}
    </MuiButton>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'orange']),
};

Button.defaultProps = {
  color: 'blue',
};

export default Button;
