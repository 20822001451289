import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { infoAlert, errorAlert } from 'shared/utilities/alerts';
import { reusableChipStyles } from 'shared/constants/styleConstants';
import FileChip from 'shared/components/FileChip/FileChip';
import { UPLOAD_FILE } from 'shared/constants/gql-constants';

const BUTTON_ID = 'RequestAttachmentsButton-upload-btn';
const openInNewTab = link => window.open(link, '_blank');

const useStyles = makeStyles(theme => ({
  uploadInput: {
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    ...reusableChipStyles,
  },
}));

function RequestAttachments({ savedFiles, setSavedFiles }) {
  // array of the File type from backend queries
  const [loading, setLoading] = useState(false);
  const [uploadFileMutation] = useMutation(UPLOAD_FILE);

  const fileStyles = useStyles();

  async function onFiles({ target: { files } }) {
    if (files.length === 0) {
      infoAlert('Please Try Again', 'No files were selected.');
      return;
    }
    setLoading(true);

    // the files list isn't quite like an actual javascript Array, so let's
    // convert it first to utilize functions like map
    const fileArray = [];
    for (let i = 0; i < files.length; i += 1) {
      fileArray.push(files[i]);
    }

    let resultFiles = [];
    try {
      // try uploading all, then await all the promises
      const uploadPromises = fileArray.map(file =>
        uploadFileMutation({ variables: { file } }),
      );

      // resolve all these uploads
      const results = await Promise.all(uploadPromises);

      resultFiles = results
        // extract the actual returned value from the promise resolutions
        .map(({ data: { uploadFile } }) => uploadFile)
        // finally, since there's some metadata/extra properties added to objects
        // resulting from graphql,strip only the needed stuff
        .map(({ filename, mimetype, url }) => ({ filename, mimetype, url }));
    } catch (err) {
      errorAlert('Unable To Upload', err.toString());
      return;
    } finally {
      setLoading(false);
    }

    setSavedFiles(savedFiles.concat(resultFiles));
  }

  // functionally removes element from array and updates uploaded files array to
  // get a re-render
  const removeIndex = index => () => {
    const newArray = savedFiles.filter((_, i) => index !== i);
    setSavedFiles(newArray);
  };

  if (loading) {
    return (
      <>
        <CircularProgress size={20} />
      </>
    );
  }

  return (
    <>
      {savedFiles.map(({ filename, url }, index) => (
        <FileChip
          label={filename}
          onClick={() => openInNewTab(url)}
          onDelete={removeIndex(index)}
        />
      ))}

      <input
        style={{ display: 'none' }}
        id={BUTTON_ID}
        type="file"
        // disallow users from uploading files while an upload is already happening
        onChange={loading ? undefined : onFiles}
      />
      <label htmlFor={BUTTON_ID}>
        <Chip
          label={loading ? 'Uploading file...' : 'Upload a file'}
          icon={<InsertDriveFileIcon />}
          clickable={!loading}
          variant="outlined"
          className={fileStyles.uploadInput}
        />
      </label>
    </>
  );
}

RequestAttachments.propTypes = {
  savedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setSavedFiles: PropTypes.func.isRequired,
};

export default RequestAttachments;
