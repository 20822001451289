import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import useIsMobile from 'shared/hooks/useIsMobile';
import Link from 'shared/components/Link/Link';
import withClient from 'shared/components/ApolloClient/withClient';
import { mapNameToColor, getInitials } from 'shared/utilities/initials';
import { GET_TOP_TUTORS } from 'shared/constants/gql-constants';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
  },
  avatarLink: {
    '&:hover': {
      cursor: 'pointer',
    },
    height: theme.spacing(15),
    width: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(15),
      width: theme.spacing(15),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  },
  avatarContainer: {
    margin: theme.spacing(2),
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  avatarInfo: {
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(15),
    textAlign: 'center',
  },
  nonLinkText: {
    textDecoration: 'none',
  },
}));

function SearchTutorNameNullState({ client }) {
  const classes = useStyles();
  const [topTutors, setTopTutors] = React.useState([]);
  const splitTutorArrays = [];
  const isMobile = useIsMobile();
  const dimension = isMobile ? 4 : 5;
  const allTutors = isMobile ? topTutors.slice(0, 8) : topTutors;

  while (allTutors.length > 0)
    splitTutorArrays.push(allTutors.splice(0, dimension));

  const fetchTopTutors = async () => {
    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_TOP_TUTORS,
        variables: {
          numberTutors: 10,
        },
      });
      setTopTutors(data.getTopActiveTutors);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    (async () => {
      fetchTopTutors();
    })();
    return undefined;
  }, []);

  return (
    <React.Fragment>
      <Grid
        container
        alignContent="center"
        justify="center"
        display="flex"
        className={classes.container}
      >
        {splitTutorArrays.map(tutorArray => (
          <Grid item key={tutorArray[0].id}>
            <Grid container alignContent="center" justify="center">
              {tutorArray.map(tutor => (
                <Link
                  key={tutor.id}
                  to={`/u/${tutor.profile.profileId}`}
                  underline="none"
                  openNewTab
                  isInternalTutorflyLink
                  className={classes.nonLinkText}
                >
                  <Grid item className={classes.avatarContainer}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid container item justify="center" alignItems="center">
                        <Avatar
                          src={tutor.profile.profilePicture.src}
                          className={classes.avatarLink}
                          alt={`${tutor.profile.firstName} ${
                            tutor.profile.lastName
                          }`}
                          style={{
                            background: mapNameToColor(
                              tutor.profile.firstName,
                              tutor.profile.lastName,
                            ),
                          }}
                        >
                          {getInitials(
                            tutor.profile.firstName,
                            tutor.profile.lastName,
                          )}
                        </Avatar>
                      </Grid>
                      <Grid container item justify="center" alignItems="center">
                        <Box className={classes.avatarInfo}>
                          <Typography variant="h5">
                            {tutor.profile.firstName}
                          </Typography>
                          <Typography variant="body2">
                            {tutor.profile.school}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Link>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}

SearchTutorNameNullState.propTypes = {
  client: PropTypes.object.isRequired, // eslint-disable-line
};
export default compose(withClient)(SearchTutorNameNullState);
