import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DefaultImage from 'shared/assets/images/student-04.png';
import ContactForm from '../../../shared/components/ContactForm/ContactForm';
import withStyles from './ContactHeroStyles';

const ContactHeroNode = ({ title, subtitle, description, image, classes }) => (
  <div className={classes.heroContent}>
    <div className={classes.innerContent}>
      <Grid container>
        <Grid item container xs={12} md={6} lg={5}>
          <Grid
            container
            justify="center"
            alignItems={{
              xs: 'start',
              lg: 'center',
            }}
            direction="column"
            wrap="nowrap"
            className={classes.textContainer}
            item
            xs={12}
          >
            <Grid item>
              <Typography variant="h4" className={classes.subtitle}>
                {subtitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: '1rem' }}>
              <p className={classes.description}>{description}</p>
            </Grid>
            <Grid item>
              <ContactForm />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={7}
          alignItems="center"
          justify="center"
        >
          <img className={classes.image} src={image} alt="A Tutorfly Student" />
        </Grid>
      </Grid>
    </div>
  </div>
);

ContactHeroNode.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  classes: PropTypes.object, // eslint-disable-line
};

ContactHeroNode.defaultProps = {
  title: 'Get support or information for the Tutorfly Marketplace',
  subtitle: '',
  description: '',
  image: DefaultImage,
};

export default withStyles(ContactHeroNode);
