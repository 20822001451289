import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const TutorCardTabs = withStyles({
  root: {
    minHeight: 0,
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export default TutorCardTabs;
