import withStyles from '@material-ui/core/styles/withStyles';
import {
  tutorflyBlue,
  buttonBlue,
  tutorflyDarkBlue,
} from 'shared/constants/styleConstants';

const styles = theme => ({
  headerTitle: {
    marginLeft: theme.spacing(2),
  },
  successText: {
    textAlign: 'center',
  },
  selectField: {
    minWidth: 200,
    width: 200,
    maxWidth: '100%',
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  dialogTitleHead: {
    paddingBottom: theme.spacing(1),
  },
  dialogContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  profileContainer: {
    paddingRight: theme.spacing(2),
  },
  dialogActions: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    color: '#333',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1, 1, 1, 1),
    margin: theme.spacing(0, 0, -1, -1),
  },
  daySelectField: {
    minWidth: 200,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    color: '#fff',
  },
  prompt: {
    lineHeight: 1.2,
  },
  field: {
    // hard coded value agreed on to better space the fields
    paddingBottom: '42px',
  },
  savePreferencesText: {
    lineHeight: 1.3,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  detailsPrompt: {
    lineHeight: 1.2,
  },
  submitButton: {
    margin: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  successIcon: {
    color: tutorflyBlue,
    height: '100px',
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '90px',
      width: '90px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '60px',
      width: '60px',
    },
  },
  hDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  hDividerNoSpacing: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '100%',
  },
  blueDivider: {
    color: tutorflyBlue,
    width: '100%',
  },
  paymentIcon: {
    color: tutorflyBlue,
    height: '70px',
    width: '70px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      width: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '50px',
      width: '50px',
    },
  },
  button: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  lineThrough: {
    position: 'relative',
    zIndex: 1,
    display: 'block',
    width: '100%',
    color: '#757575',
    textAlign: 'center',
    fontSize: '80%',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: '-1',
      marginTop: '-5px',
      marginLeft: '-90px',
      width: '180px',
      height: '10px',
      backgroundColor: '#fff',
      content: '""',
    },
    '&:after': {
      position: 'absolute',
      top: '49%',
      zIndex: '-2',
      display: 'block',
      width: '100%',
      borderBottom: '1px solid #ddd',
      content: '""',
    },
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  textCta: {
    color: buttonBlue,
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  stripeConnectSuccessMessage: {
    color: tutorflyDarkBlue,
    textAlign: 'center',
  },
  addPaymentContainer: {
    marginBottom: theme.spacing(2),
  },
  secondaryCtaContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  formControl: {
    marginTop: '1em',
  },
  paper: {
    background: '#D7D7D7',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  errorText: {
    paddingTop: theme.spacing(2),
  },
});

export default withStyles(styles);
